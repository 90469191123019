<template>
    <b-row align-h="center">
        <!--
            vouchers
            -->
        <b-col cols="12">
            <b-card bg-variant="transparent" border-variant="lighter">
                <b-card-header header-bg-variant="transparent" header-border-variant="white">
                    <b-row>
                        <b-col>
                            <h3 class="mb-0 text-primary text-uppercase">
                                Vouchers registrados
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding postulantVoucher loading -->
                <div style="height:250px;" v-show="vouchersDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- vouchers -->
                <b-row align-h="start">
                    <b-col 
                        v-for="(voucher, i) in vouchersData" :key="`${i}-voucher`"
                        cols="12"
                        lg="6"
                        class="mb-4"
                        v-show="!vouchersDataLoading"
                    >
                        <b-card class="shadow">
                            <b-row>
                                <!-- opciones -->
                                <b-col cols="12" class="text-right mb-2">
                                    <refund-voucher-component v-show="voucher.attributes.refundedVoucher == null ? true : false"
                                        :ui="'employee'" :voucherData="voucher" @created="reloadData" :order="i">
                                    </refund-voucher-component>
                                </b-col>

                                <!-- voucher_image -->
                                <b-col md="6" class="align-self-center">
                                    <b-card-img
                                        :src="voucher.attributes.image"
                                        alt="voucher"
                                        fluid
                                        center
                                        class="apk-voucher"
                                        style="border: gray 1px solid"
                                    ></b-card-img>

                                    <div
                                        class="apk-voucher-backdrop"
                                        @click="voucherImageClicked(voucher.attributes)"
                                    >
                                        <b-iconstack font-scale="1.5" class="apk-voucher-backdrop-icon-one" variant="primary">
                                            <b-icon stacked icon="fullscreen" scale="1.5"></b-icon>
                                            <b-icon stacked icon="image"></b-icon>
                                        </b-iconstack>

                                        <b-iconstack font-scale="2.5" class="apk-voucher-backdrop-icon-two">
                                            <b-icon stacked icon="fullscreen" scale="1.5"></b-icon>
                                            <b-icon stacked icon="image"></b-icon>
                                        </b-iconstack>
                                    </div>
                                </b-col>

                                <!-- voucher_details -->
                                <b-col md="6">
                                    <b-card-text>
                                        <b-list-group>
                                            <b-list-group-item class="text-body-1" variant="primary">
                                                <b-icon icon="bookmark-fill" class="mr-1"></b-icon>
                                                {{ voucher.attributes.voucher_concept }}
                                            </b-list-group-item>

                                            <b-list-group-item class="text-body-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin mr-1" viewBox="0 0 16 16">
                                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                                                </svg>
                                                {{ voucher.attributes.amount_format }}
                                            </b-list-group-item>

                                            <b-list-group-item class="text-body-1" v-show="voucher.attributes.type == 'pago normal'">
                                                <b-iconstack class="mr-1">
                                                    <b-icon stacked icon="square"></b-icon>
                                                    <b-icon stacked icon="hash"></b-icon>
                                                </b-iconstack>
                                                {{ voucher.attributes.code }}
                                            </b-list-group-item>

                                            <b-list-group-item class="text-body-1">
                                                <b-icon icon="file-text" class="mr-1"></b-icon>
                                                {{ voucher.attributes.description }}
                                            </b-list-group-item>

                                            <b-list-group-item class="text-body-1"
                                                :class="voucher.attributes.status == 'verificado' ? 'text-green' :'text-yellow' "
                                            >
                                                <b-icon
                                                    :icon="voucher.attributes.status == 'verificado' ? 'check-square-fill' : 'exclamation-square-fill'"
                                                    class="mr-1"
                                                ></b-icon> {{ voucher.attributes.status }}
                                            </b-list-group-item>

                                            <b-list-group-item class="text-body-1">
                                                <b-icon icon="calendar-fill" class="mr-1"></b-icon>
                                                {{ voucher.attributes.date }}
                                            </b-list-group-item>

                                            <b-list-group-item class="text-body-1">
                                                <b-icon icon="alarm-fill" class="mr-1"></b-icon>
                                                {{ voucher.attributes.time }}
                                            </b-list-group-item>
                                        </b-list-group>
                                    </b-card-text>
                                </b-col>

                                <!-- refunded vouchers -->
                                <b-col cols="12" class="mt-5" v-if="voucher.attributes.refundedVoucher == null ? false : true">
                                    <show-refund-voucher-component
                                        :ui="'employee'" @deleted="reloadData"
                                        :refundedVoucher="voucher.attributes.refundedVoucher">
                                    </show-refund-voucher-component>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
               
            </b-card>
        </b-col>

        <!-- 
            voucher modal
            -->
        <b-modal
            id="modal-voucher-details"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">DETALLES DEL VOUCHER</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                clearInputsAndStates()
                                close()
                                }">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- voucher image -->
                        <b-col cols="12" lg="7" class="mb-4">
                            <b-img
                                :src="voucherSelected.image"
                                fluid
                                :class="[voucherSelected.status == 'verificado' ? 'voucher-sucess' : 'voucher-warning']"
                                rounded=""
                            ></b-img>
                        </b-col>

                        <!-- voucher form -->
                        <b-col cols="12" lg="5">
                            <b-form @submit.prevent="updateVoucher">
                                <!-- voucher_concept -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Concepto del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="text"
                                                disabled
                                                v-model="voucherSelected.voucher_concept"
                                                :state="showModalInputStatus('voucher_concept')"
                                                v-show="!voucherSelected.updateVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('voucher_concept')"
                                                :key="`${i}-updateVoucher-concept`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- type -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Tipo de pago:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="voucherSelected.type"
                                                :options="voucherTypeOptions"
                                                :state="showModalInputStatus('type')"
                                                v-show="!voucherSelected.updateVoucherLoading || false"
                                                @change="voucherTypeChange"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('type')"
                                                :key="`${i}-updateVoucher-type`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- code -->
                                <b-form-row v-show="showCode">
                                    <b-col>
                                        <b-form-group
                                            label="Código de operación del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                placeholder="Ingrese el código de operación"
                                                v-model="voucherSelected.code"
                                                :state="showModalInputStatus('code')"
                                                v-show="!voucherSelected.updateVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('code')"
                                                :key="`${i}-updateVoucher-code`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- amount -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Monto:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="number"
                                                step="0.01"
                                                v-model="voucherSelected.amount"
                                                :state="showModalInputStatus('amount')"
                                                v-show="!voucherSelected.updateVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('amount')"
                                                :key="`${i}-updateVoucher-amount`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- date -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Fecha de operación del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="voucherSelected.date"
                                                :state="showModalInputStatus('date')"
                                                v-if="!voucherSelected.updateVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('date')"
                                                :key="`${i}-createVoucher-date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- time -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Hora de operación del voucher:"
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                show-seconds
                                                v-model="voucherSelected.time"
                                                :state="showModalInputStatus('time')"
                                                v-if="!voucherSelected.updateVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('time')"
                                                :key="`${i}-createVoucher-time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- status -->
                                <b-form-row v-show="hasAdminRole">
                                    <b-col>
                                        <b-form-group
                                            label="Estado:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-select
                                                v-model="voucherSelected.status"
                                                :options="voucherStatusOptions"
                                                :state="showModalInputStatus('status')"
                                                v-show="!voucherSelected.updateVoucherLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('status')"
                                                :key="`${i}-updateVoucher-status`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="voucherSelected.updateVoucherLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="voucherSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!voucherSelected.updateVoucherLoading || false"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-updateVoucher-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            ACTUALIZAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- INVALID VOUCHERS -->
        <b-col cols="12" class="mt-5" v-if="invalidVouchersData.length != 0">
            <b-card bg-variant="transparent" border-variant="lighter">
                <b-card-header header-bg-variant="transparent" header-border-variant="white">
                    <b-row>
                        <b-col>
                            <h3 class="mb-0 text-magenta text-uppercase">
                                Vouchers no válidos
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="magenta" size="sm" v-b-toggle="'collapse-invalid-vouchers'">
                                <b-icon icon="eye" class="mr-1"></b-icon> {{ invalidVouchersData.length }}
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:250px;" v-show="vouchersDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- vouchers -->
                <b-collapse id="collapse-invalid-vouchers">
                    <b-row align-h="start">
                        <b-col 
                            v-for="(voucher, i) in invalidVouchersData" :key="`${i}-voucher`"
                            cols="12"
                            lg="6"
                            class="mb-4"
                            v-show="!vouchersDataLoading"
                        >
                            <b-card class="shadow">
                                <b-row>
                                    <b-col md="6" class="align-self-center">
                                        <b-card-img
                                            :src="voucher.attributes.image"
                                            alt="voucher"
                                            fluid
                                            center
                                            class="apk-voucher border border-magenta"
                                            style="border: gray 1px solid"
                                        ></b-card-img>
                                    </b-col>

                                    <b-col md="6">
                                        <b-card-text>
                                            <b-list-group>
                                                <b-list-group-item class="text-body-1" variant="magenta">
                                                    <b-icon icon="bookmark-fill" class="mr-1"></b-icon>
                                                    {{ voucher.attributes.voucher_concept }}
                                                </b-list-group-item>

                                                <b-list-group-item class="text-body-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin mr-1" viewBox="0 0 16 16">
                                                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                                                    </svg>
                                                    {{ voucher.attributes.amount_format }}
                                                </b-list-group-item>

                                                <b-list-group-item class="text-body-1" v-show="voucher.attributes.type == 'pago normal'">
                                                    <b-iconstack class="mr-1">
                                                        <b-icon stacked icon="square"></b-icon>
                                                        <b-icon stacked icon="hash"></b-icon>
                                                    </b-iconstack>
                                                    {{ voucher.attributes.code }}
                                                </b-list-group-item>

                                                <b-list-group-item class="text-body-1">
                                                    <b-icon icon="file-text" class="mr-1"></b-icon>
                                                    {{ voucher.attributes.description }}
                                                </b-list-group-item>

                                                <b-list-group-item class="text-body-1 text-magenta" >
                                                    <b-icon
                                                        icon= 'x-square-fill' class="mr-1"
                                                    ></b-icon> {{ voucher.attributes.status }}
                                                </b-list-group-item>

                                                <b-list-group-item class="text-body-1">
                                                    <b-icon icon="calendar-fill" class="mr-1"></b-icon>
                                                    {{ voucher.attributes.date }}
                                                </b-list-group-item>

                                                <b-list-group-item class="text-body-1">
                                                    <b-icon icon="alarm-fill" class="mr-1"></b-icon>
                                                    {{ voucher.attributes.time }}
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-card-text>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-collapse>
               
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getPostulantVouchers, updatePostulantVoucher } from '@/api/ProgramSeasonPostulant/PostulantVoucher'
import { getContractVouchers, updateContractVoucher } from '@/api/ProgramSeasonPostulant/ContractVoucher'
import swal from 'sweetalert'
import RefundVoucherComponent from '@/components/Admin-Employee/Postulant-Participant/RefundVoucherComponent.vue'
import ShowRefundVoucherComponent from '@/components/Admin-Employee/Postulant-Participant/ShowRefundVoucherComponent.vue'

export default {
    name: 'Vouchers',
    props: {
        reload: {
            type: Boolean
        }
    },
    components: {
        RefundVoucherComponent,
        ShowRefundVoucherComponent
    },
    data: () => ({
        //vouchers
        vouchersData: [],
        vouchersDataLoading: false,
        //modal
        voucherSelected: {},
        voucherTypeOptions: [
            { value: 'pago normal', text: 'Pago Normal' },
            { value: 'pago por aplicativo', text: 'Pago por Yape o Plin' },
            { value: 'nota de crédito', text: 'Nota de crédito' },
        ],
        voucherStatusOptions: [
            { value: 'verificado', text: 'verificado' },
            { value: 'no verificado', text: 'no verificado' },
        ],
        showCode: true,
        //
        initialModalInputValues: true,
        modalInputErrors: {},
        //
        invalidVouchersData: []
    }),

    created () {
        this.getPostulantVouchers()
    },

    watch: {
        reload () {
            if (this.reload) this.getPostulantVouchers()
            // else console.log('need reload: ', this.reload);
        }
    },

    computed: {
        hasAdminRole () {
            return this.$store.getters['user/hasRoleAndIsActive']('admin')
        },
    },

    methods: {
        // Vouchers
        getPostulantVouchers () {
            this.vouchersDataLoading = true
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant // ok
            getPostulantVouchers(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    let voucher_data = data.data
                    getContractVouchers(IdProgramSeasonPostulant)
                        .then(({ data }) => {
                            for (const contractVoucher of data.data) {
                                voucher_data.push(contractVoucher)
                            }
                            this.invalidVouchersData = voucher_data.filter(el => el.attributes.status == 'no valido')
                            this.vouchersData = voucher_data.filter(el => el.attributes.status != 'no valido')
                        })
                })
                .finally(() => {
                    this.vouchersDataLoading = false
                    this.$emit('vouchersReloaded')
                })
        },

        // Modal Voucher Details
        voucherImageClicked (pVoucherData) {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            //
            if (pVoucherData.type == 'pago normal' || pVoucherData.type == 'nota de crédito') this.showCode = true
            else this.showCode = false
            //
            this.voucherSelected = {...pVoucherData}
            this.$bvModal.show('modal-voucher-details')
        },

        voucherTypeChange (e) {
            if(e == 'pago normal' || e == 'nota de crédito') this.showCode = true
            else this.showCode = false
        },

        updateVoucher () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.$set(this.voucherSelected, 'updateVoucherLoading', true)

            let VoucherForm = new FormData()

            VoucherForm.append('.method', 'put')
            
            VoucherForm.append('voucher_concept', this.voucherSelected.voucher_concept)
            VoucherForm.append('type', this.voucherSelected.type)

            if(this.showCode) VoucherForm.append('code', this.voucherSelected.code)
            else VoucherForm.append('code', '')

            VoucherForm.append('amount', this.voucherSelected.amount)
            VoucherForm.append('date', this.voucherSelected.date)
            VoucherForm.append('time', this.voucherSelected.time)
            VoucherForm.append('description', this.voucherSelected.description)
            VoucherForm.append('status', this.voucherSelected.status)

            //
            if (Object.keys(this.voucherSelected).includes('postulantPayment_id')) {
                // console.log('es postulantVoucher')
                VoucherForm.append('IdPostulantVoucher', this.voucherSelected.id)
                VoucherForm.append('postulantPayment_id', this.voucherSelected.postulantPayment_id)

                updatePostulantVoucher(VoucherForm, this.voucherSelected.id)
                    .then(() => {
                        swal('Actualización correcta!', 'Voucher del postulante actualizado', 'success')
                            .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.clearInputsAndStates()
                                        this.getPostulantVouchers()
                                        this.$emit('voucherUpdated')
                                        this.$bvModal.hide('modal-voucher-details')
                                        break;

                                    default:
                                        console.log('updatePostulantVouchers--swal-break');
                                        break;
                                }
                            })
                    })
                    .catch(err => {
                        console.log('updatePostulantVouchers err: ', err.response);
                        if (err.response.status === 422) {
                            this.modalInputErrors = err.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.$set(this.voucherSelected, 'updateVoucherLoading', false)
                        console.log('updatePostulantVouchers end');
                    })

            } else if (Object.keys(this.voucherSelected).includes('contractPayment_id')) {
                // console.log('es contractVoucher')
                VoucherForm.append('IdContractVoucher', this.voucherSelected.id)
                VoucherForm.append('contractPayment_id', this.voucherSelected.contractPayment_id)

                updateContractVoucher(VoucherForm, this.voucherSelected.id)
                    .then(() => {
                        swal('Actualización correcta!', 'Voucher del contrato actualizado', 'success')
                            .then(value => {
                                switch (value) {
                                    case true:
                                    case null:
                                        this.clearInputsAndStates()
                                        this.getPostulantVouchers()
                                        this.$emit('voucherUpdated')
                                        this.$bvModal.hide('modal-voucher-details')
                                        break;

                                    default:
                                        console.log('updateContractVoucher--swal-break');
                                        break;
                                }
                            })
                    })
                    .catch(err => {
                        console.log('updateContractVoucher err: ', err.response);
                        if (err.response.status === 422) {
                            this.modalInputErrors = err.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.$set(this.voucherSelected, 'updateVoucherLoading', false)
                        console.log('updateContractVoucher end');
                    })
            }
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            this.voucherSelected = {}
            this.showCode = true
        },

        reloadData () {
            this.getPostulantVouchers()
            // this.$emit('vouchersReloaded')
            // this.$emit('voucherUpdated')
        },
    }
}
</script>

<style scoped>
.voucher-sucess {
    border:1px solid rgba(0, 128, 0, 0.5);
    cursor: pointer;
}
.voucher-warning {
    border:1px solid rgba(255, 166, 0, 0.45);
    border-style: dotted;
    cursor: pointer;
}
.apk-voucher-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.0);
    border-radius: 8px;
    transition: ease-in .300s all;
    cursor: pointer;
}
.apk-voucher-backdrop:hover {
    background-color: rgba(0, 0, 0, 0.45);
}
/* icon-one */
.apk-voucher-backdrop-icon-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.15;
    color: white;
    transition: ease-in .300s all;
}
.apk-voucher-backdrop:hover .apk-voucher-backdrop-icon-one {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
}
/* icon-two */
.apk-voucher-backdrop-icon-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    color: white;
    transition: ease-in .300s all;
}
.apk-voucher-backdrop:hover .apk-voucher-backdrop-icon-two {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
</style>