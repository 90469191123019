import { get } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

function getProgramResources (pIdProgramSeason) {
    const URI = `${BASE_URL}/api/programSeasons/${pIdProgramSeason}/programResources`
    return get(URI)
}
export {
    getProgramResources,
}