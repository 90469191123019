<template>
    <b-row class="justify-content-center">
        <b-col cols="12" sm="10">
            <b-card class="shadow mb-5">
                <b-card-body class="p-0">
                    <b-row>
                        <b-col>
                            <b-form-radio-group v-model="currentUI" :options="options" @change="checkUI"></b-form-radio-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>

            <b-card class="shadow" :no-body="currentUI == 'postulant-english-promotion'">
                <!-- create postulant with postulant-new-user-view default -->
                <transition name="fade" mode="out-in" duration="10"  appear>
                    <component :is="currentUI" />
                </transition>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import PostulantNewUser from '@/views/Employee/Postulants/SalesAdvisor/PostulantsCreate/PostulantNewUser'
import PostulantExistingUser from '@/views/Employee/Postulants/SalesAdvisor/PostulantsCreate/PostulantExistingUser'
import PostulantEnglishPromotion from '@/views/Employee/Postulants/SalesAdvisor/PostulantsCreate/PostulantEnglishPromotion'

export default {
    name: "PostulantsCreate",
    components: {
        PostulantNewUser,
        PostulantExistingUser,
        PostulantEnglishPromotion
    },
    data: () => ({
       currentUI: 'postulant-new-user',
       options: [
            {value: 'postulant-new-user', text: 'Postulante nuevo'},
            {value: 'postulant-existing-user', text: 'Postulante existente'},
            {value: 'postulant-english-promotion', text: 'Promoción 2x1'},
       ],
    }),
    methods: {
        checkUI (e) {
            this.currentUI = e
        }
    },
}
</script>