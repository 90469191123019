<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista de tópicos de charlas
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="modalTalkCreateClick">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th><div>video</div><div>(link)</div></th>
                            <th>nombre</th>
                            <th>estado</th>
                            <th>descripción</th>
                            <th></th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <b-button variant="outline-primary" size="sm" :href="row.attributes.link" target="_black">
                                    <b-icon icon="image" scale="1.2"></b-icon>
                                </b-button>
                            </td>
                            <td class="text-overline">{{ row.attributes.topic_name }}</td>
                            <td >{{ row.attributes.status }}</td>
                            <td>
                                {{ row.attributes.description ? row.attributes.description.substr(0, 60) : '' }} ...
                            </td>

                            <td class="text-right">
                                <b-btn size="sm" @click="modalTalkEditClick(row.attributes)">
                                    <b-icon icon="pencil-square" scale="1.2" variant="primary"></b-icon>
                                </b-btn>
                            </td>
                            <td class="text-right">
                                <b-btn size="sm" @click="deleteTalk(row.attributes)">
                                    <b-icon icon="trash" scale="1.2" variant="danger"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!--
            modal talk topic create
        -->
        <b-modal
            id="modal-talk-create-topic"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">CREAR TÓPICO DE CHARLA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalTalkCreateClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- talk form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="createTalkTopic">
                                <!-- topic_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre : "
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="topic_name"
                                                :state="showModalInputStatus('topic_name')"
                                                v-show="!createTalkLoading"
                                                placeholder="Ingrese el nombre de la capacitación"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('topic_name')"
                                                :key="`${i}-create-topic_name`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- link -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Video (link) : "
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="link"
                                                :state="showModalInputStatus('link')"
                                                v-show="!createTalkLoading"
                                                placeholder="Ingrese el link del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-create-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- iframe -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Iframe : "
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="iframe"
                                                :state="showModalInputStatus('iframe')"
                                                v-show="!createTalkLoading"
                                                placeholder="Ingrese el iframe del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('iframe')"
                                                :key="`${i}-create-iframe`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!createTalkLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="createTalkLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        
        <!--
            modal talk topic edit
        -->
        <b-modal
            id="modal-talk-edit-topic"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDITAR TÓPICO DE CHARLA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalTalkEditClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- talk form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editTalkTopic">
                                <!-- topic_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre : "
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="talkSelected.topic_name"
                                                :state="showModalInputStatus('topic_name')"
                                                v-show="!createTalkLoading"
                                                placeholder="Ingrese el nombre de la capacitación"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('topic_name')"
                                                :key="`${i}-create-topic_name`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- link -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Video (link) : "
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="talkSelected.link"
                                                :state="showModalInputStatus('link')"
                                                v-show="!createTalkLoading"
                                                placeholder="Ingrese el link del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-create-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- iframe -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Iframe : "
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="talkSelected.iframe"
                                                :state="showModalInputStatus('iframe')"
                                                v-show="!createTalkLoading"
                                                placeholder="Ingrese el iframe del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('iframe')"
                                                :key="`${i}-create-iframe`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="createTalkLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="talkSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!createTalkLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="createTalkLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
/** programsSeason.talks */
import { getProgramSeasonTalks } from '@/api/ProgramSeason/ProgramSeasonTalks';
import { storeTalk, updateTalk, destroyTalk } from '@/api/Talk/Talk'
import swal from 'sweetalert'

export default {
    name: "ProgramTalksTopics",
    data: () => ({
        // table 
        tableData: [],
        loadingTableData: false,
        // modal create talk topic
        topic_name: '',
        link: '',
        iframe: '',
        description :'',
        // modal edit talk topic
        talkSelected: {},

        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        createTalkLoading: false,
    }),
    created() {
        this.gerTalks()
    },
    methods: {
        gerTalks () {
            this.loadingTableData = true

            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            getProgramSeasonTalks(IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .catch( err => {
                    console.log('getProgramSeasonTalks Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getProgramSeasonTalks request end');
                })
        },

        // Modal CREATE
        modalTalkCreateClick () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-talk-create-topic')
        },
        modalTalkCreateClose () {
            this.$bvModal.hide('modal-talk-create-topic')
            this.clearInputsAndStates()
        },

        createTalkTopic () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.createTalkLoading = true

            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            let TalkForm = new FormData()
            TalkForm.append('programSeason_id', IdProgramSeason)
            TalkForm.append('topic_name', this.topic_name)
            TalkForm.append('link', this.link)
            TalkForm.append('ifrane', this.ifrane == null ? '' : this.ifrane )
            TalkForm.append('description', this.description == null ? '' : this.description )

            storeTalk(TalkForm)
                .then(({ data }) => {
                    console.log('createTalkTopic res: ', data);
                    swal('Creación correcta!', 'Charla creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.gerTalks()
                                    this.$bvModal.hide('modal-talk-create-topic')
                                    break;

                                default:
                                    console.log('createTalkTopic--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('createTalkTopic err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createTalkLoading = false
                    console.log('request createTalkTopic end');
                })
        },

        // Modal EDIT
        modalTalkEditClick (pTalk) {
            this.clearInputsAndStates()
            this.talkSelected = { ...pTalk }
            this.$bvModal.show('modal-talk-edit-topic')
        },
        modalTalkEditClose () {
            this.$bvModal.hide('modal-talk-edit-topic')
            this.clearInputsAndStates()
        },

        editTalkTopic () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.createTalkLoading = true

            let TalkForm = new FormData()
            TalkForm.append('.method', 'put')
            TalkForm.append('topic_name', this.talkSelected.topic_name)
            TalkForm.append('link', this.talkSelected.link)
            TalkForm.append('ifrane', this.talkSelected.ifrane == null ? '' : this.talkSelected.ifrane )
            TalkForm.append('description', this.talkSelected.description == null ? '' : this.talkSelected.description )

            updateTalk(TalkForm, this.talkSelected.id)
                .then(({ data }) => {
                    console.log('updateTalkTopic res: ', data);
                    swal('Actualización correcta!', 'Charla actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.gerTalks()
                                    this.$bvModal.hide('modal-talk-edit-topic')
                                    break;
                                default:
                                    console.log('updateTalkTopic--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('updateTalkTopic err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createTalkLoading = false
                    console.log('request updateTalkTopic end');
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.topic_name = ''
            this.link = ''
            this.ifrane = ''
            this.description = ''
            //
            this.talkSelected = {}
        },

        //
        deleteTalk (pTalk) {
            swal('Se eliminará la charla.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                    .then(value => {
                        switch (value) {
                            case true:
                                destroyTalk(pTalk.id)
                                    .then(() => {
                                         swal('Se eliminó la charla!', 'Eliminado', 'success')
                                            .then(value => {
                                                switch (value) {
                                                    case true:
                                                    case null:
                                                        this.clearInputsAndStates()
                                                        this.gerTalks()
                                                        break;
                                                    default:
                                                        console.log('talk-swal-break');
                                                        break;
                                                }
                                            })
                                    })
                                break;
                            case null:
                                break;
                            default:
                                console.log('-swal-break');
                                break;
                        }
                    })
        },

        reloadData () {
            this.gerTalks()
        }
        
    }
}
</script>