<template>
    <program-talks-topics></program-talks-topics>
</template>

<script>
import ProgramTalksTopics from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramTalks/ProgramTalksTopics.vue'

export default {
    name: "TraineeTalksTopics",
    components: { ProgramTalksTopics },
}
</script>