<template>
    <b-row>
        <b-col>
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                list
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <!-- <th>sponsor</th> -->
                            <th>programa</th>
                            <th>temporada</th>
                            <!-- <th>descripción</th> -->
                            <th class="text-center">ofertas laborales</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <!-- <td>{{ row.attributes.sponsor_name }}</td> -->
                            <td>{{ row.attributes.program }}</td>
                            <td>{{ row.attributes.season }}</td>
                            <!-- <td>{{ row.attributes.description }}</td> -->

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: 'participant-program-sponsor-joboffers',
                                        params: { 'IdProgramSeasonSponsor': row.id }    //IdProgramSeasonSponsor
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stack" viewBox="0 0 16 16">
                                        <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z"/>
                                        <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z"/>
                                    </svg>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// programSeason.programSeasonSponsor
import { getProgramSeasonProgramSeasonSponsors } from '@/api/ProgramSeason/ProgramSeasonProgramSeasonSponsor';
export default {
    name: "SponsorsList",
    props: {
        routeName : {
            type: String
        }
    },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData (pPage=1) {
            this.loadingTableData = true
            let IdProgramSeason = this.$route.params.IdProgramSeason    // ok
            getProgramSeasonProgramSeasonSponsors(pPage, IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },
        reloadData() {
            this.getData()
        },
    },
}
</script>