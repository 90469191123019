<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista de tópicos de charlas
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th><div>video</div><div>(link)</div></th>
                            <th>nombre</th>
                            <th>estado</th>
                            <th>descripción</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <b-button variant="outline-primary" size="sm" :href="row.attributes.link" target="_black">
                                    <b-icon icon="image" scale="1.2"></b-icon>
                                </b-button>
                            </td>
                            <td class="text-overline">{{ row.attributes.topic_name }}</td>
                            <td >{{ row.attributes.status }}</td>
                            <td>
                                {{ row.attributes.description ? row.attributes.description.substr(0, 60) : '' }} ...
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
/** programsSeason.talks */
import { getProgramSeasonTalks } from '@/api/ProgramSeason/ProgramSeasonTalks';

export default {
    name: "TalksTopics",
    data: () => ({
        // table 
        tableData: [],
        loadingTableData: false,
    }),
    created() {
        this.gerTalks()
    },
    methods: {
        gerTalks () {
            this.loadingTableData = true

            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            getProgramSeasonTalks(IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .catch( err => {
                    console.log('getProgramSeasonTalks Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getProgramSeasonTalks request end');
                })
        },
        reloadData () {
            this.gerTalks()
        }
        
    }
}
</script>