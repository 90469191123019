<template>
    <intranet-nav-content :bgColor="'primary'">
        <template slot="links">
            <div class="d-flex align-items-center">
                <intranet-nav-content-link-exact :link="{ name: 'Au pair List', path: { name: 'employee-aupair-list' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'As. de Ventas', path: { name: 'employee-wat-list-SA' } }"/> -->
            </div>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'EmployeeAupair',
    components: { 
        IntranetNavContent,
        // IntranetNavContentLink,
        IntranetNavContentLinkExact
    },
};
</script>