<template>
    <b-row align-h="center">
        <!-- PAYMENTS -->
        <b-col cols="12" class="mb-4">
            <payments
                :reload="reloadPaymentsTable"
                @paymentsReloaded="reloadPayments(false)"
                @voucherCreated="reloadVouchers(true)"
            />
        </b-col>

        <!-- VOUCHERS -->
        <b-col cols="12">
            <vouchers
            :reload="reloadVouchersTable"
                @voucherUpdated="reloadPayments(true)"
                @vouchersReloaded="reloadPayments(false)"
            />
        </b-col>
    </b-row>
</template>

<script>
import Payments from './PaymentsVouchersComponents/Payments.vue'
import Vouchers from './PaymentsVouchersComponents/Vouchers.vue'

export default {
    name: 'PostulantPaymentsVouchers',
    components: {
        Payments,
        Vouchers
    },
    data: () => ({
        reloadPaymentsTable: false,
        reloadVouchersTable: false
    }),
    methods: {
        reloadPayments (pValue) {
            this.reloadPaymentsTable = pValue
        },
        reloadVouchers (pValue) {
            this.reloadVouchersTable = pValue
        }
    }
}
</script>