<template>
    <postulant-details-component :ui="'cultural_advisor'" :routeToList="'employee-postulants-list-CA'"></postulant-details-component>
</template>

<script>
import PostulantDetailsComponent from '@/components/Admin-Employee/Postulant/Details/PostulantDetailsComponent.vue'

export default {
    name: "PostulantDetails",
    components: { PostulantDetailsComponent },
    data: () => ({}),
}
</script>