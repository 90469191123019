<template>
    <b-row class="mb-4">
        <b-col cols="12" class="mb-4">
            <!-- card -->
            <b-card class="shadow" no-body>
                <!-- card header -->
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                LISTA DE RECURSOS
                            </h3>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="naranja" size="sm" @click="$bvModal.show('modal-resource-create')">
                                CREAR <b-icon icon="file-earmark-text" scale="1"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Archivo</th>
                            <th>Recurso</th>
                            <th>descripción</th>
                            <th></th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                            <td>
                                <!-- <a :href="row.attributes.file" target="_blank"> -->
                                <b-button variant="outline-primary" size="sm" :href="row.attributes.file" target="_black">
                                    <b-icon icon="file-earmark-text" class="m-0 p-0"></b-icon>
                                </b-button>
                            <!-- </a> -->
                            </td>

                            <td>{{ row.attributes.name}}</td>

                            <td>{{ row.attributes.description}}</td>

                            <td class="text-right">
                                <b-btn 
                                    variant="primary"
                                    size="sm"
                                    @click="clickModalEditResource(row.attributes)"
                                ><b-icon icon="pencil-square" class="m-0 p-0"></b-icon>
                                </b-btn>
                            </td>

                            <td class="text-center">
                                <b-btn 
                                    variant="danger"
                                    size="sm"
                                    @click="deleteResource(row.attributes)"
                                ><b-icon icon="trash2" class="m-0 p-0"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- 
            voucher resource create
            -->
        <b-modal
            id="modal-resource-create"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">CREAR RECURSO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                clearInputsAndStates()
                                close()
                                }">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- resource form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="createResource">
                                <!-- name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre del recurso:" 
                                        >
                                            <pulse-loader
                                                :loading="createResourceLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Ingrese el nombre del recurso."
                                                v-model="name"
                                                :state="showModalInputStatus('name')"
                                                v-show="!createResourceLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('name')"
                                                :key="`${i}-create-resource-name`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- TYPE FILE -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group>
                                            <b-form-radio-group v-model="type_file" @change="typeDocChange">
                                                <b-form-radio value="documento">Documento</b-form-radio>
                                                <b-form-radio value="url">Url</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- file -->
                                <b-form-row v-if="type_file == 'documento'">
                                    <b-col>
                                        <b-form-group
                                            label="Archivo (doc.):" 
                                        >
                                            <pulse-loader
                                                :loading="createResourceLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-file
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!createResourceLoading || false"
                                                style="overflow:hidden;"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-create-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- url -->
                                <b-form-row v-if="type_file == 'url'">
                                    <b-col>
                                        <b-form-group
                                            label="Archivo (url):" 
                                        >
                                            <pulse-loader
                                                :loading="createResourceLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="url"
                                                placeholder="Ingrese la url de recurso."
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!createResourceLoading || false"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-create-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="createResourceLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!createResourceLoading || false"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- 
            voucher resource create
            -->
        <b-modal
            id="modal-resource-edit"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">EDITAR RECURSO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                clearInputsAndStates()
                                close()
                                }">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- resource form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editProgramResource">
                                <!-- name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre del recurso:" 
                                        >
                                            <pulse-loader
                                                :loading="editResourceLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Ingrese el nombre del recurso."
                                                v-model="resourceSelected.name"
                                                :state="showModalInputStatus('name')"
                                                v-show="!editResourceLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('name')"
                                                :key="`${i}-edit-resource-name`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- TYPE FILE -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group>
                                            <b-form-radio-group v-model="type_file" @change="typeDocChange">
                                                <b-form-radio value="documento">Documento</b-form-radio>
                                                <b-form-radio value="url">Url</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- file -->
                                <b-form-row v-if="type_file == 'documento'">
                                    <b-col>
                                        <b-form-group
                                            label="Archivo (doc.):" 
                                        >
                                            <pulse-loader
                                                :loading="editResourceLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-file
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!editResourceLoading"
                                                style="overflow:hidden;"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-edit-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- url -->
                                <b-form-row v-if="type_file == 'url'">
                                    <b-col>
                                        <b-form-group
                                            label="Archivo (url):" 
                                        >
                                            <pulse-loader
                                                :loading="editResourceLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-input
                                                type="url"
                                                v-model="resourceSelected.file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!editResourceLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-edit-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="editResourceLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #d1d7dc"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="resourceSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!editResourceLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-edit-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit">
                                            ACTUALIZAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getProgramResources } from '@/api/ProgramSeason/ProgramSeasonProgramResource';
import { storeProgramResource, updateProgramResource,destroyResource } from '@/api/ProgramResource/ProgramResource'
import swal from 'sweetalert'

export default {
    name: "ProgramResources",
    components: {
    },
    data: () => ({
        tableData: [],
        loadingTableData: false,
        // modal form
        name : '',
        type_file: 'url',
        file : null,
        description : '',
        //
        resourceSelected: {},
        editResourceLoading: false,
        //
        initialModalInputValues : true,
        modalInputErrors: '',
        createResourceLoading: false,
    }),
    created() {
        this.getProgramResourcesData()
    },
    methods: {
        // Resource Table Data
        getProgramResourcesData () {
            this.loadingTableData = true
            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok
            getProgramResources(IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        typeDocChange() {
            this.file = null
        },

        // Delete Resource
        deleteResource (pProgramResource) {
            swal(
                 '¿Está seguro de eliminar el recurso?',
                 `Se eliminará el recurso: ${pProgramResource.name}.`,
                'error',
                { dangerMode: true, buttons: ['cancelar', 'si'] }
            )
                .then(value => {
                    switch (value) {
                        case true:
                            console.log('se eliminará x1');
                            this.loadingTableData = true
                            destroyResource(pProgramResource.id)
                                .then(res => {
                                    console.log('destroyResource res: ', res);
                                    swal('Recurso eliminado!', `${res.status}`, 'success')
                                        .then(val => {
                                            switch (val) {
                                                case null:
                                                case true:
                                                    console.log('se elimino x2');
                                                    this.getProgramResourcesData()
                                                    break;
                                                default:
                                                    console.log('deleteResource-2--swal-break');
                                                    break;
                                            }
                                        })
                                })
                                .catch((err)=> {
                                    if (err) console.log('err:' , err);
                                    console.log('destroyResource err', err.response);
                                })
                                .finally(()=> {
                                    this.loadingTableData = false
                                    console.log('request destroyResource end');
                                })
                            break;
                        case null:
                        case false:
                            console.log('marica');
                            break;
                        default:
                            console.log('deleteResource-1--swal-break');
                            break;
                    }
                })
                
                
        },

        // Modal Methods
        createResource () {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.createResourceLoading = true

            let IdProgramSeason = this.$route.params.IdProgramSeason // ok

            let ResourceForm = new FormData()
            ResourceForm.append('programSeason_id', IdProgramSeason)
            ResourceForm.append('name', this.name)
            ResourceForm.append('type_file', this.type_file)
            ResourceForm.append('file', this.file)
            ResourceForm.append('description', this.description)

            storeProgramResource(ResourceForm)
                .then(() => {
                    swal('Creación correcta!', 'Recurso creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getProgramResourcesData()
                                    this.$bvModal.hide('modal-resource-create')
                                    break;

                                default:
                                    console.log('createResource--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createResourceLoading = false
                })
        },

        // Edit Resource
        clickModalEditResource (pProgramResource) {
            this.clearInputsAndStates()
            this.resourceSelected = {...pProgramResource}
            this.$bvModal.show('modal-resource-edit')
        },
        closeModalEditResource () {
            this.clearInputsAndStates()
            this.$bvModal.hide('modal-resource-edit')
        },

        editProgramResource() {
            this.modalInputErrors = {}
            this.initialModalInputValues = false
            this.editResourceLoading = true

            let ResourceForm = new FormData()
            ResourceForm.append('.method', 'put')
            ResourceForm.append('name', this.resourceSelected.name)
            ResourceForm.append('type_file', this.type_file)
            ResourceForm.append('file', this.type_file == 'documento' ? this.file : this.resourceSelected.file)
            ResourceForm.append('description', this.resourceSelected.description)

            updateProgramResource(this.resourceSelected.id ,ResourceForm)
                .then(() => {
                    swal('Actualización correcta!', 'Recurso actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getProgramResourcesData()
                                    this.closeModalEditResource()
                                    break;

                                default:
                                    console.log('editResource--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.editResourceLoading = false
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            //
            this.name = ''
            this.type_file = 'url'
            this.file = null
            this.description = ''
            //
            this.resourceSelected = {}
        }
    }
}
</script>