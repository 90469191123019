<template>
    <program-sponsor-joboffers />
</template>

<script>
import ProgramSponsorJoboffers from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorDetails/ProgramSponsorJoboffers.vue'

export default {
    name: "InternshipSponsorJobOffers",
    components: { ProgramSponsorJoboffers }
}
</script>