<template>
    <!-- create postulant with new user -->
    <b-form @submit.prevent="createPostulant">
        <!-- nombres -->
        <b-form-row class="my-2">
            <b-col cols="12" xl="5">
                <b-form-group
                    label="Nombres"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese los nombres."
                        v-model="name"
                        :state="showInputStatus('name')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('name')" :key="`${i}-createPostulant-name`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>

        <!-- apellidos -->
            <b-col cols="12" xl="7">
                <b-form-group
                    label="Apellidos"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese los apellidos."
                        v-model="lastName"
                        :state="showInputStatus('last_name')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('last_name')" :key="`${i}-createPostulant-lastName`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- tipo-doc -->
        <b-form-row class="my-2">
            <b-col cols="12" xl="5">
                <b-form-group
                    label="Tipo de documento"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-select 
                        v-model="documentType"
                        :options="documentTypeOptions"
                        :state="showInputStatus('document_type')"
                        v-show="!createPostulantLoading"
                    >
                    </b-form-select>

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('document_type')" :key="`${i}-createPostulant-documentType`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>

        <!-- num-doc -->
            <b-col cols="12" xl="7">
                <b-form-group
                    label="Número de documento"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="number"
                        placeholder="Ingrese el número de documento."
                        v-model="documentNumber"
                        :state="showInputStatus('document_number')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('document_number')" :key="`${i}-createPostulant-documentNumber`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- celular -->
        <b-form-row class="my-2">
            <b-col cols="12" xl="5">
                <b-form-group
                    label="Número de celular"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="number"
                        placeholder="Ingrese el número de celular"
                        v-model="phoneNumber"
                        :state="showInputStatus('phone_number')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('phone_number')" :key="`${i}-createPostulant-phoneNumber`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>

        <!-- correo -->
            <b-col cols="12" xl="7">
                <b-form-group
                    label="Correo"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="email"
                        placeholder="Ingrese el correo."
                        v-model="email"
                        :state="showInputStatus('email')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('email')" :key="`${i}-createPostulant-email`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- skype -->
        <b-form-row class="my-2">
            <b-col cols="12" xl="5">
                <b-form-group
                    label="Skype ID"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese el SKYPE ID."
                        v-model="skype"
                        :state="showInputStatus('skype')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('skype')" :key="`${i}-createPostulant-skype`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        
        <!-- profile -->
            <b-col cols="12" xl="7">
                <b-form-group
                    label="Link del perfil"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-input
                        type="text"
                        placeholder="Ingrese el link del perfil."
                        v-model="profile"
                        :state="showInputStatus('profile')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('profile')" :key="`${i}-createPostulant-profile`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- descripción -->
        <b-form-row class="my-2">
            <b-col cols="12">
                <b-form-group
                    label="Descripción"
                >
                    <pulse-loader
                        :loading="createPostulantLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-textarea 
                        placeholder="Ingrese una descripción (opcional)"
                        v-model="programSeasonPostulantDescription"
                        :state="showInputStatus('programSeason_description')"
                        v-show="!createPostulantLoading"
                    />

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('programSeason_description')" :key="`${i}-createPostulant-description`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row class="my-2">
            <!-- programa * temporada -->
            <b-col cols="12" xl="5">
                <b-form-group
                    label="Programa - Temporada"
                >
                    <pulse-loader
                        :loading="createPostulantLoading || getProgramSeasonOptionsLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-select 
                        :disabled="getCulturalAdvisorLoading"
                        v-model="programSeasonSelected"
                        :options="programSeasonOptions"
                        :state="showInputStatus('programSeason_id')"
                        v-show="!createPostulantLoading && !getProgramSeasonOptionsLoading"
                        @change="getCulturalAdvisor"
                    >
                        <template #first>
                            <b-form-select-option value="" disabled>
                                -- Seleccione un programa * temporada --
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('programSeason_id')" :key="`${i}-createPostulant-programSeason_id`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>

            <!-- asesor cultural -->
            <b-col cols="12" xl="7">
                <b-form-group
                    label="Asesor Cultural"
                >
                    <pulse-loader
                        :loading="createPostulantLoading || getCulturalAdvisorLoading"
                        :color="'#5e72e4'"
                        :margin="'5px'"
                        :size="10"
                        class="pt-2 pb-1 pl-2 text-center"
                        style="border-radius:5px; border:1px solid #d1d7dc"
                    />

                    <b-form-select 
                        v-model="culturalAdvisorSelected"
                        :options="culturalAdvisorOptions"
                        :state="showInputStatus('culturalAdvisorAssigned_id') "
                        v-show="!createPostulantLoading && !getCulturalAdvisorLoading"
                    >
                        <template #first>
                            <b-form-select-option value="" disabled>
                                -- Seleccione un asesor cultural --
                            </b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('culturalAdvisorAssigned_id')" :key="`${i}-createPostulant-culturalAdvisorAssigned_id`">
                        {{ inputError }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- voucher form -->
        <b-card :border-variant="borderByProgram" v-show="programSeasonSelected ? true : false">
            <b-form @submit.prevent="createVoucher" class="mt-4">
                <b-form-row>
                    <!-- voucher_concept -->
                    <b-col cols="12" xl="6">
                        <b-form-group
                            label="Concepto del voucher:" 
                        >
                            <pulse-loader
                                :loading="createPostulantLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="10"
                                class="pt-2 pb-1 pl-2"
                                style="border-radius:5px; border:1px solid #d1d7dc"
                            />

                            <b-form-input
                                type="text"
                                disabled
                                v-model="voucher_concept"
                                :state="showInputStatus('voucher_concept')"
                                v-show="!createPostulantLoading"
                            />

                            <b-form-invalid-feedback
                                v-for="(inputError, i) in showInputErrors('voucher_concept')"
                                :key="`${i}-createVoucher-concept`"
                            >
                                {{ inputError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <!-- amount -->
                    <b-col cols="12" xl="6">
                        <b-form-group
                            label="Monto:" 
                        >
                            <pulse-loader
                                :loading="createPostulantLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="10"
                                class="pt-2 pb-1 pl-2"
                                style="border-radius:5px; border:1px solid #d1d7dc"
                            />

                            <b-form-input
                                placeholder="Ingrese el monto de la entrevista."
                                type="number"
                                step="0.01"
                                v-model="amount"
                                :state="showInputStatus('amount')"
                                v-show="!createPostulantLoading"
                            />

                            <b-form-invalid-feedback
                                v-for="(inputError, i) in showInputErrors('amount')"
                                :key="`${i}-createVoucher-amount`"
                            >
                                {{ inputError }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-form-row>

                <!-- image -->
                <b-row  align-h="center">
                    <!-- preview image -->
                    <b-col cols="12" xl="6" class="mb-4 d-block" >
                        <b-img
                            title="voucher"
                            :src="preview_image"
                            fluid
                            class="voucher-preview"
                            rounded=""
                        ></b-img>
                    </b-col>
                    
                    <!-- voucher-img -->
                    <b-col cols="12" xl="6">
                        <b-form-group
                            label="Imagen del voucher:" 
                        >
                            <pulse-loader
                                :loading="createPostulantLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="10"
                                class="pt-2 pb-1 pl-2"
                                style="border-radius:5px; border:1px solid #d1d7dc"
                            />

                            <b-form-file
                                v-model="image"
                                :state="showInputStatus('image')"
                                v-show="!createPostulantLoading"
                                style="overflow:hidden;"
                                @change="showImagePreview"
                            />

                            <b-form-invalid-feedback
                                v-for="(inputError, i) in showInputErrors('image')"
                                :key="`${i}-createVoucher-image`"
                            >
                                {{ inputError }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- type -->
                        <b-form-group
                            label="Tipo de pago:" 
                        >
                            <pulse-loader
                                :loading="createPostulantLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="10"
                                class="pt-2 pb-1 pl-2"
                                style="border-radius:5px; border:1px solid #d1d7dc"
                            />

                            <b-form-select
                                v-model="type"
                                :options="voucherTypeOptions"
                                :state="showInputStatus('type')"
                                v-show="!createPostulantLoading"
                                @change="voucherTypeChange"
                            >
                                <template #first>
                                    <b-form-select-option value="" disabled>
                                        -- Seleccione el tipo de pago --
                                    </b-form-select-option>
                                </template>
                            </b-form-select>

                            <b-form-invalid-feedback
                                v-for="(inputError, i) in showInputErrors('type')"
                                :key="`${i}-createVoucher-type`"
                            >
                                {{ inputError }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- code -->
                        <b-form-group
                                label="Código de operación del voucher:" 
                                v-show="showCode"
                            >
                                <pulse-loader
                                    :loading="createPostulantLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #d1d7dc"
                                />

                                <b-form-input
                                    type="number"
                                    placeholder="Ingrese el código de operación."
                                    v-model="code"
                                    :state="showInputStatus('code')"
                                    v-show="!createPostulantLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('code')"
                                    :key="`${i}-createVoucher-code`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- date -->
                        <b-form-group
                                label="Fecha de operación del voucher:" 
                            >
                                <pulse-loader
                                    :loading="createPostulantLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #d1d7dc"
                                />

                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    selected-variant="primary"
                                    today-variant="naranja"
                                    locale="es"

                                    today-button
                                    label-today-button="today"
                                    today-button-variant="outline-naranja"
                                    
                                    reset-button
                                    label-reset-button="reset"
                                    reset-button-variant="outline-magenta"

                                    close-button
                                    label-close-button="ok"
                                    close-button-variant="primary"

                                    v-model="date"
                                    :state="showInputStatus('date')"
                                    v-if="!createPostulantLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('date')"
                                    :key="`${i}-createVoucher-date`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- time -->
                        <b-form-group
                                label="Hora de operación del voucher:"
                            >
                                <pulse-loader
                                    :loading="createPostulantLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #d1d7dc"
                                />

                                <b-form-timepicker
                                    locale="en"

                                    now-button
                                    label-now-button="now"
                                    now-button-variant="outline-naranja"

                                    reset-button
                                    label-reset-button="reset"
                                    reset-button-variant="outline-magenta"

                                    close-button
                                    label-close-button="ok"
                                    close-button-variant="primary"

                                    show-seconds
                                    v-model="time"
                                    :state="showInputStatus('time')"
                                    v-if="!createPostulantLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('time')"
                                    :key="`${i}-createVoucher-time`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                        </b-form-group>

                    <!-- description -->
                        <b-form-group
                                label="Descripción:" 
                            >
                                <pulse-loader
                                    :loading="createPostulantLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #d1d7dc"
                                />

                                <b-form-textarea 
                                    placeholder="Ingrese una descripción (opcional)."
                                    v-model="voucher_description"
                                    :state="showInputStatus('voucher_description')"
                                    v-show="!createPostulantLoading"
                                    rows="3"
                                    max-rows="6"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('voucher_description')"
                                    :key="`${i}-updateVoucher-voucher_description`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <!-- btn crear -->
        <b-form-row class="justify-content-center">
            <b-col cols="12" sm="auto">
                <b-button
                    class="w-100 my-2"
                    variant="primary"
                    type="submit"
                    size="lg"
                    :disabled="createPostulantLoading"
                >CREAR POSTULANTE</b-button>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<script>
import { storePostulant } from '@/api/Postulant/Postulant'
import { getAllProgramSeasons } from '@/api/ProgramSeason/ProgramSeason'
import { getAllActiveCulturalAdvisorsByProgram } from '@/api/CulturalAdvisor/CulturalAdvisor'
import swal from 'sweetalert'
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'PostulantNewUser',
    //components: { PulseLoader },
    data: () => ({
        //form
        name: '',
        lastName: '',
        documentType: 'dni',
        documentNumber: '',
        phoneNumber: '',
        email: '',
        skype: '',
        profile: '',
        programSeasonPostulantDescription: '',
        // voucher
        voucher_concept: 'Entrevista de Inglés',
        amount: '',
        type : '',
        code : '',
        image : null,
        date : '',
        time : '',
        voucher_description : '',
        //
        createPostulantLoading: false,
        // programSeason
        programSeasonData: [],
        programSeasonOptions: [],
        programSeasonSelected: '',
        programIDSelected: '',
        getProgramSeasonOptionsLoading: false,
        // culturalAdvisor
        culturalAdvisorOptions: [],
        culturalAdvisorSelected: '',
        getCulturalAdvisorLoading: false,
        //
        documentTypeOptions: [
            { value: 'extranjeria', text: 'carnet de extranjería' },
            { value: 'dni', text: 'DNI' }
        ],
        //
        voucherTypeOptions: [
            { value: 'pago normal', text: 'Pago Normal' },
            { value: 'pago por aplicativo', text: 'Pago por Yape o Plin' },
            { value: 'nota de crédito', text: 'Nota de crédito' },
        ],
        showCode: true,
        preview_image: '/img/theme/voucher_info.jpg',
        //
        initialInputValues: true,
        inputErrors: {},
    }),

    created () {
        this.getProgramSeasonsData()
    },

    computed: {
        salesAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-ventas')
        },
        borderByProgram() {
            let borderColor
            for (const el of this.programSeasonData) {
                if( el.id == this.programSeasonSelected) {
                    switch (el.attributes.program_id.toString()) {
                        case '1':
                            borderColor = 'primary'
                            break
                        case '2':
                        case '3':
                            borderColor = 'cielo'
                            break
                        case '4':
                            borderColor = 'naranja'
                            break
                        case '5':
                            borderColor = 'green'
                            break
                        default:
                            console.log('borderByProgram psID:'+ this.programSeasonSelected + ' breack - color');
                            break;
                    }
                    break;
                } else {
                    borderColor = 'magenta'
                }
            }
            return borderColor
        }
    },

    methods: {
        createPostulant () {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createPostulantLoading = true

            //
            let IdSalesAdvisor = this.salesAdvisorPermissionData.attributes.typeAdvisor_id   // ok

            let PostulantFormData = new FormData()

            PostulantFormData.append('register','sales_advisor')

            PostulantFormData.append('name', this.name)
            PostulantFormData.append('last_name', this.lastName)
            PostulantFormData.append('document_type', this.documentType)
            PostulantFormData.append('document_number', this.documentNumber)
            PostulantFormData.append('phone_number', this.phoneNumber)
            PostulantFormData.append('email', this.email)
            // postulant
            PostulantFormData.append('skype', this.skype)
            PostulantFormData.append('profile', this.profile)
            PostulantFormData.append('description', `postulante ${this.lastName}`)

            // programSeasonPostulant
            PostulantFormData.append('programSeason_id', this.programSeasonSelected)
            PostulantFormData.append('salesAdvisor_id', IdSalesAdvisor)
            PostulantFormData.append('culturalAdvisorAssigned_id', this.culturalAdvisorSelected)
            PostulantFormData.append('programSeason_description', this.programSeasonPostulantDescription)

            // postulantVoucher - entrevista de ingles
            PostulantFormData.append('amount', this.amount)
            PostulantFormData.append('type', this.type)

            if(this.showCode) PostulantFormData.append('code', this.code)
            else PostulantFormData.append('code', '')

            PostulantFormData.append('image', this.image)
            PostulantFormData.append('date', this.date)
            PostulantFormData.append('time', this.time)
            PostulantFormData.append('voucher_description', this.voucher_description)


            storePostulant(PostulantFormData)
                .then(() => {
                     swal('Creación correcta!', 'Nuevo postulante creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'employee-postulants-list-SA'})
                                    break;

                                default:
                                    console.log('postulantCreate-PostulantNewUser-storePostulant-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createPostulantLoading = false
                    console.log('request create postulant end');
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        // programSeason
        getProgramSeasonsData () {
            this.getProgramSeasonOptionsLoading = true

            getAllProgramSeasons()
                .then(({data}) => {
                    this.programSeasonData = data.data
                    this.programSeasonOptions = data.data
                                                    .filter(el => el.attributes.status == 'activado')
                                                    .map(el => ({ value: el.attributes.id, text: `${el.attributes.program_name} * ${el.attributes.season_name}` }))
                })
                .catch(err => {
                    console.log('getAllProgramSeasons error', err.response);
                })
                .finally(() => {
                    this.getProgramSeasonOptionsLoading = false
                })
        },

        // culturalAdvisor 
        getCulturalAdvisor (event) {
            this.getCulturalAdvisorLoading = true
            this.culturalAdvisorSelected = ''

            let programId
            for (const el of this.programSeasonData) {
                if( el.id == event) {
                    switch (el.attributes.program_id.toString()) {
                        case '1':
                            programId = 1
                            break
                        case '2':
                            programId = 2
                            break
                        case '3':
                            programId = 3
                            break
                        case '4':
                            programId = 4
                            break
                        case '5':
                            programId = 5
                            break
                        default:
                            console.log('programIdByProgramSeasonID: '+ event  + ' breack - getCA');
                            break;
                    }
                    break;
                }
            }

            getAllActiveCulturalAdvisorsByProgram(programId)
                .then(({ data }) => {
                    this.culturalAdvisorOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name }))
                })
                .catch(err => {
                    console.log('get all cultural advisor activate error :', err.response);
                })
                .finally(() => {
                    this.getCulturalAdvisorLoading = false
                })
        },

        // voucher
        voucherTypeChange (e) {
            if(e == 'pago normal' || e == 'nota de crédito') this.showCode = true
            else this.showCode = false
        },

        showImagePreview (e) {
            if (e.target.files.length >= 1) {
                // capturar img del input
                let image = e.target.files[0]
                //crear reader
                let reader = new FileReader()
                // código al cargar la img
                reader.onload = (ee) => {
                    this.preview_image = ee.target.result
                }
                // asignar la img
                reader.readAsDataURL(image)
            }
        },
    },
}
</script>

<style scoped>
.voucher-preview {
    border:1px solid rgba(0, 0, 0, 0.15);
}
</style>