<template>
    <!-- create postulant with new user -->
    <b-form @submit.prevent="createPostulant">
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-tabs card v-model="tabIndex" fill >
                        <!-- tab: partner one -->
                        <b-tab title="Primer postulante" active>
                            <!-- search users -->
                            <b-row align-h="end" class="mb-6">
                                <b-col cols="auto" v-show="partner_one_selected" class="border border-magenta py-2" style="border-radius: 5px">
                                    {{ partner_one.attributes.last_name }} {{ partner_one.attributes.name }}<b-button variant="magenta" size="sm" @click="clearPartnerData('pOne')" class="ml-2"><b-icon icon="x"/></b-button>
                                </b-col>

                                <b-col cols="auto" v-show="!partner_one_selected">
                                    <b-button variant="primary" size="sm" @click="modalSearchUsersClick('pOne')">BUSCAR 1ER POSTULANTE<b-icon icon="search" class="ml-2"/></b-button>
                                </b-col>
                            </b-row>

                            <!-- nombres -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Nombres"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese los nombres."
                                            v-model="partner_one.attributes.name"
                                            :state="showInputStatus('partner_one_name')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_name')" :key="`${i}-createPostulant-name`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                            <!-- apellidos -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Apellidos"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese los apellidos."
                                            v-model="partner_one.attributes.last_name"
                                            :state="showInputStatus('partner_one_last_name')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_last_name')" :key="`${i}-createPostulant-lastName`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- tipo-doc -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Tipo de documento"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            v-model="partner_one.attributes.document_type"
                                            :options="documentTypeOptions"
                                            :state="showInputStatus('partner_one_document_type')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one_selected"
                                        >
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_document_type')" :key="`${i}-createPostulant-documentType`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                            <!-- num-doc -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Número de documento"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="number"
                                            placeholder="Ingrese el número de documento."
                                            v-model="partner_one.attributes.document_number"
                                            :state="showInputStatus('partner_one_document_number')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_document_number')" :key="`${i}-createPostulant-documentNumber`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- celular -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Número de celular"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="number"
                                            placeholder="Ingrese el número de celular"
                                            v-model="partner_one.attributes.phone_number"
                                            :state="showInputStatus('partner_one_phone_number')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_phone_number')" :key="`${i}-createPostulant-phoneNumber`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                            <!-- correo -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Correo"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="email"
                                            placeholder="Ingrese el correo."
                                            v-model="partner_one.attributes.email"
                                            :state="showInputStatus('partner_one_email')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_email')" :key="`${i}-createPostulant-email`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- skype -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Skype ID"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el SKYPE ID."
                                            v-model="partner_one.attributes.postulant_skype"
                                            :state="showInputStatus('partner_one_skype')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one.attributes.hasBeenPostulant"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_skype')" :key="`${i}-createPostulant-skype`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- profile -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Link del perfil"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el link del perfil."
                                            v-model="partner_one.attributes.postulant_profile"
                                            :state="showInputStatus('partner_one_profile')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_one.attributes.hasBeenPostulant"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_profile')" :key="`${i}-createPostulant-profile`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- descripción -->
                            <b-form-row class="my-2">
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea 
                                            placeholder="Ingrese una descripción (opcional)"
                                            v-model="partner_one.attributes.description"
                                            :state="showInputStatus('partner_one_programSeason_description')"
                                            v-show="!createPostulantLoading"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_programSeason_description')" :key="`${i}-createPostulant-description`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- programa * temporada -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Programa - Temporada"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading || getProgramSeasonOptionsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            :disabled="partner_one.attributes.getCulturalAdvisorLoading"
                                            v-model="partner_one.attributes.programSeasonSelected"
                                            :options="partner_one.attributes.programSeasonOptions"
                                            :state="showInputStatus('partner_one_programSeason_id') && showInputStatus('partner_one_programSeason_code')"
                                            v-show="!createPostulantLoading && !getProgramSeasonOptionsLoading"
                                            @change="(e) => getCulturalAdvisor('pOne', e)"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>
                                                    -- Seleccione un programa * temporada --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_programSeason_id')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_programSeason_code')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- asesor cultural -->
                                <b-col cols="12" xl="7" v-if="postulantCreateUI == 'sa'">
                                    <b-form-group
                                        label="Asesor Cultural"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading || partner_one.attributes.getCulturalAdvisorLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            v-model="partner_one.attributes.culturalAdvisorSelected"
                                            :options="partner_one.attributes.culturalAdvisorOptions"
                                            :state="showInputStatus('partner_one_culturalAdvisorAssigned_id')"
                                            v-show="!createPostulantLoading && !partner_one.attributes.getCulturalAdvisorLoading"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>
                                                    -- Seleccione un asesor cultural --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_one_culturalAdvisorAssigned_id')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                        </b-tab>

                        <!-- tab: partner two -->
                        <b-tab title="Segundo postulante">
                            <!-- search users -->
                            <b-row align-h="end" class="mb-6">
                                <b-col cols="auto" v-show="partner_two_selected" class="border border-magenta py-2" style="border-radius: 5px">
                                    {{ partner_two.attributes.last_name }} {{ partner_two.attributes.name }}<b-button variant="magenta" size="sm" @click="clearPartnerData('pTwo')" class="ml-2"><b-icon icon="x"/></b-button>
                                </b-col>

                                <b-col cols="auto" v-show="!partner_two_selected">
                                    <b-button variant="primary" size="sm" @click="modalSearchUsersClick('pTwo')">BUSCAR 2DO POSTULANTE<b-icon icon="search" class="ml-2"/></b-button>
                                </b-col>
                            </b-row>

                            <!-- nombres -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Nombres"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese los nombres."
                                            v-model="partner_two.attributes.name"
                                            :state="showInputStatus('partner_two_name')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_name')" :key="`${i}-createPostulant-name`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- apellidos -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Apellidos"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese los apellidos."
                                            v-model="partner_two.attributes.last_name"
                                            :state="showInputStatus('partner_two_last_name')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_last_name')" :key="`${i}-createPostulant-lastName`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- tipo-doc -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Tipo de documento"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            v-model="partner_two.attributes.document_type"
                                            :options="documentTypeOptions"
                                            :state="showInputStatus('partner_two_document_type')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two_selected"
                                        >
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_document_type')" :key="`${i}-createPostulant-documentType`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- num-doc -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Número de documento"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="number"
                                            placeholder="Ingrese el número de documento."
                                            v-model="partner_two.attributes.document_number"
                                            :state="showInputStatus('partner_two_document_number')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_document_number')" :key="`${i}-createPostulant-documentNumber`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- celular -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Número de celular"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="number"
                                            placeholder="Ingrese el número de celular"
                                            v-model="partner_two.attributes.phone_number"
                                            :state="showInputStatus('partner_two_phone_number')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_phone_number')" :key="`${i}-createPostulant-phoneNumber`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                            <!-- correo -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Correo"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="email"
                                            placeholder="Ingrese el correo."
                                            v-model="partner_two.attributes.email"
                                            :state="showInputStatus('partner_two_email')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two_selected"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_email')" :key="`${i}-createPostulant-email`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- skype -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Skype ID"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el SKYPE ID."
                                            v-model="partner_two.attributes.postulant_skype"
                                            :state="showInputStatus('partner_two_skype')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two.attributes.hasBeenPostulant"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_skype')" :key="`${i}-createPostulant-skype`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                        <!-- profile -->
                                <b-col cols="12" xl="7">
                                    <b-form-group
                                        label="Link del perfil"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-input
                                            type="text"
                                            placeholder="Ingrese el link del perfil."
                                            v-model="partner_two.attributes.postulant_profile"
                                            :state="showInputStatus('partner_two_profile')"
                                            v-show="!createPostulantLoading"
                                            :disabled="partner_two.attributes.hasBeenPostulant"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_profile')" :key="`${i}-createPostulant-profile`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- descripción -->
                            <b-form-row class="my-2">
                                <b-col cols="12">
                                    <b-form-group
                                        label="Descripción"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-textarea 
                                            placeholder="Ingrese una descripción (opcional)"
                                            v-model="partner_two.attributes.description"
                                            :state="showInputStatus('partner_two_programSeason_description')"
                                            v-show="!createPostulantLoading"
                                        />

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_programSeason_description')" :key="`${i}-createPostulant-description`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- programa * temporada -->
                            <b-form-row class="my-2">
                                <b-col cols="12" xl="5">
                                    <b-form-group
                                        label="Programa - Temporada"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading || getProgramSeasonOptionsLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            :disabled="partner_two.attributes.getCulturalAdvisorLoading"
                                            v-model="partner_two.attributes.programSeasonSelected"
                                            :options="partner_two.attributes.programSeasonOptions"
                                            :state="showInputStatus('partner_two_programSeason_id') && showInputStatus('partner_two_programSeason_code')"
                                            v-show="!createPostulantLoading && !getProgramSeasonOptionsLoading"
                                            @change="(e) => getCulturalAdvisor('pTwo', e)"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>
                                                    -- Seleccione un programa * temporada --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_programSeason_id')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_programSeason_code')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- asesor cultural -->
                                <b-col cols="12" xl="7" v-if="postulantCreateUI == 'sa'">
                                    <b-form-group
                                        label="Asesor Cultural"
                                    >
                                        <pulse-loader
                                            :loading="createPostulantLoading || partner_two.attributes.getCulturalAdvisorLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            v-model="partner_two.attributes.culturalAdvisorSelected"
                                            :options="partner_two.attributes.culturalAdvisorOptions"
                                            :state="showInputStatus('partner_two_culturalAdvisorAssigned_id')"
                                            v-show="!createPostulantLoading && !partner_two.attributes.getCulturalAdvisorLoading"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>
                                                    -- Seleccione un asesor cultural --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('partner_two_culturalAdvisorAssigned_id')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                        </b-tab>

                        <!-- tab: vouchers -->
                        <b-tab title="Voucher(s)">
                            <!-- payment count - own -->
                            <b-form-row class="my-2">
                                <!-- payment count -->
                                <b-col cols="12" xl="3">
                                    <b-form-group label="Cantidad de pagos">
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            v-model="payment_count"
                                            :options="paymentCountOptions"
                                            :state="showInputStatus('payment_count')"
                                            v-show="!createPostulantLoading"
                                            @change="paymentCountChange"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>
                                                    -- Seleccione la cantidad de pagos --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('payment_count')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>

                                <!-- payment own -->
                                <b-col cols="12" xl="9">
                                    <b-form-group label="Responsable del pago">
                                        <pulse-loader
                                            :loading="createPostulantLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                            style="border-radius:5px; border:1px solid #5e72e4"
                                        />

                                        <b-form-select 
                                            v-model="payment_own"
                                            :options="paymentOwnOptions"
                                            :state="showInputStatus('payment_own')"
                                            v-show="!createPostulantLoading"
                                            @change="paymentOwnChange"
                                        >
                                            <template #first>
                                                <b-form-select-option value="" disabled>
                                                    -- Seleccione al responsable del pago --
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('payment_own')" :key="`${i}-createPostulant-programSeason_id`">
                                            {{ inputError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>

                            <!-- payments -->
                            <b-row>
                                <!-- Partner one payment -->
                                <b-col v-show="partnerOnePayment"
                                    :class="payment_own == 'both' ? 'px-1 mb-3 mb-xl-0' : 'px-0'"
                                    :cols="payment_own == 'both' ? '12' : null" :xl="payment_own == 'both' ? '6' : null"
                                >
                                    <b-card border-variant="employer-interview-0">
                                        <!-- part_one_fullName -->
                                        <div class="text-center text-uppercase text-schedule-capacitation-1 border border-schedule-capacitation-1 py-2 mb-4" style="border-radius: 5px">
                                            1ER POST. : {{ partner_one.attributes.last_name }} {{ partner_one.attributes.name }}
                                        </div>

                                        <!-- voucherConcept -->
                                        <b-form-row>
                                            <!-- voucher_concept -->
                                            <b-col cols="12" xl="6">
                                                <b-form-group
                                                    label="Concepto del voucher x1:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        type="text"
                                                        disabled
                                                        v-model="voucher_concept"
                                                        :state="showInputStatus('voucher_concept')"
                                                        v-show="!createPostulantLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('voucher_concept')"
                                                        :key="`${i}-createVoucher-concept`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- amount -->
                                            <b-col cols="12" xl="6">
                                                <b-form-group
                                                    label="Monto:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        placeholder="Ingrese el monto de la entrevista."
                                                        type="number"
                                                        step="0.01"
                                                        v-model="partner_one.attributes.voucher_amount"
                                                        :state="showInputStatus('partner_one_voucher_amount')"
                                                        v-show="!createPostulantLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('partner_one_voucher_amount')"
                                                        :key="`${i}-createVoucher-amount`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <!-- image -->
                                        <b-row  align-h="center">
                                            <!-- preview image -->
                                            <b-col cols="12" xl="6"  class="mb-4 d-block" >
                                                <b-img
                                                    title="voucher"
                                                    :src="partner_one.attributes.preview_image"
                                                    fluid
                                                    class="voucher-preview"
                                                    rounded=""
                                                ></b-img>
                                            </b-col>
                                            
                                            <!-- voucher-img -->
                                            <b-col cols="12" xl="6">
                                                <b-form-group
                                                    label="Imagen del voucher:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-file
                                                        v-model="partner_one.attributes.voucher_image"
                                                        :state="showInputStatus('partner_one_voucher_image')"
                                                        v-show="!createPostulantLoading"
                                                        style="overflow:hidden;"
                                                        @change="(e) => showImagePreview('pOne', e)"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('partner_one_voucher_image')"
                                                        :key="`${i}-createVoucher-image`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- type -->
                                                <b-form-group
                                                    label="Tipo de pago:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-select
                                                        v-model="partner_one.attributes.voucher_type"
                                                        :options="voucherTypeOptions"
                                                        :state="showInputStatus('partner_one_voucher_type')"
                                                        v-show="!createPostulantLoading"
                                                        @change="(e) => voucherTypeChange('pOne', e)"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option value="" disabled>
                                                                -- Seleccione el tipo de pago --
                                                            </b-form-select-option>
                                                        </template>
                                                    </b-form-select>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('partner_one_voucher_type')"
                                                        :key="`${i}-createVoucher-type`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- code -->
                                                <b-form-group
                                                        label="Código de operación del voucher:" 
                                                        v-show="partner_one.attributes.showCode"
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-input
                                                            type="number"
                                                            placeholder="Ingrese el código de operación."
                                                            v-model="partner_one.attributes.voucher_code"
                                                            :state="showInputStatus('partner_one_voucher_code')"
                                                            v-show="!createPostulantLoading"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_one_voucher_code')"
                                                            :key="`${i}-createVoucher-code`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- date -->
                                                <b-form-group
                                                        label="Fecha de operación del voucher:" 
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-datepicker
                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                            selected-variant="primary"
                                                            today-variant="naranja"
                                                            locale="es"

                                                            today-button
                                                            label-today-button="today"
                                                            today-button-variant="outline-naranja"
                                                            
                                                            reset-button
                                                            label-reset-button="reset"
                                                            reset-button-variant="outline-magenta"

                                                            close-button
                                                            label-close-button="ok"
                                                            close-button-variant="primary"

                                                            v-model="partner_one.attributes.voucher_date"
                                                            :state="showInputStatus('partner_one_voucher_date')"
                                                            v-if="!createPostulantLoading"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_one_voucher_date')"
                                                            :key="`${i}-createVoucher-date`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- time -->
                                                <b-form-group
                                                        label="Hora de operación del voucher:"
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-timepicker
                                                            locale="en"

                                                            now-button
                                                            label-now-button="now"
                                                            now-button-variant="outline-naranja"

                                                            reset-button
                                                            label-reset-button="reset"
                                                            reset-button-variant="outline-magenta"

                                                            close-button
                                                            label-close-button="ok"
                                                            close-button-variant="primary"

                                                            show-seconds
                                                            v-model="partner_one.attributes.voucher_time"
                                                            :state="showInputStatus('partner_one_voucher_time')"
                                                            v-if="!createPostulantLoading"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_one_voucher_time')"
                                                            :key="`${i}-createVoucher-time`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- description -->
                                                <b-form-group
                                                        label="Descripción:" 
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-textarea 
                                                            placeholder="Ingrese una descripción (opcional)."
                                                            v-model="partner_one.attributes.voucher_description"
                                                            :state="showInputStatus('partner_one_voucher_description')"
                                                            v-show="!createPostulantLoading"
                                                            rows="3"
                                                            max-rows="6"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_one_voucher_description')"
                                                            :key="`${i}-updateVoucher-voucher_description`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </b-col>

                                <!-- Partner two payment -->
                                <b-col v-show="partnerTwoPayment"
                                    :class="payment_own == 'both' ? 'px-1' : 'px-0'"
                                    :cols="payment_own == 'both' ? '12' : null" :xl="payment_own == 'both' ? '6' : null"
                                >
                                    <b-card border-variant="employer-interview-0">
                                        <!-- part_two_fullName -->
                                        <div class="text-center text-uppercase text-schedule-capacitation-1 border border-schedule-capacitation-1 py-2 mb-4" style="border-radius: 5px">
                                            2DO POST. :{{ partner_two.attributes.last_name }} {{ partner_two.attributes.name }}
                                        </div>

                                        <!-- voucherConcept -->
                                        <b-form-row>
                                            <!-- voucher_concept -->
                                            <b-col cols="12" xl="6">
                                                <b-form-group
                                                    label="Concepto del voucher x2:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        type="text"
                                                        disabled
                                                        v-model="voucher_concept"
                                                        :state="showInputStatus('voucher_concept')"
                                                        v-show="!createPostulantLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('voucher_concept')"
                                                        :key="`${i}-createVoucher-concept`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>

                                            <!-- amount -->
                                            <b-col cols="12" xl="6">
                                                <b-form-group
                                                    label="Monto:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-input
                                                        placeholder="Ingrese el monto de la entrevista."
                                                        type="number"
                                                        step="0.01"
                                                        v-model="partner_two.attributes.voucher_amount"
                                                        :state="showInputStatus('partner_two_voucher_amount')"
                                                        v-show="!createPostulantLoading"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('partner_two_voucher_amount')"
                                                        :key="`${i}-createVoucher-amount`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-form-row>

                                        <!-- image -->
                                        <b-row  align-h="center">
                                            <!-- preview image -->
                                            <b-col cols="12" xl="6"  class="mb-4 d-block" >
                                                <b-img
                                                    title="voucher"
                                                    :src="partner_two.attributes.preview_image"
                                                    fluid
                                                    class="voucher-preview"
                                                    rounded=""
                                                ></b-img>
                                            </b-col>
                                            
                                            <!-- voucher-img -->
                                            <b-col cols="12" xl="6">
                                                <b-form-group
                                                    label="Imagen del voucher:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-file
                                                        v-model="partner_two.attributes.voucher_image"
                                                        :state="showInputStatus('partner_two_voucher_image')"
                                                        v-show="!createPostulantLoading"
                                                        style="overflow:hidden;"
                                                        @change="(e) => showImagePreview('pTwo', e)"
                                                    />

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('partner_two_voucher_image')"
                                                        :key="`${i}-createVoucher-image`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- type -->
                                                <b-form-group
                                                    label="Tipo de pago:" 
                                                >
                                                    <pulse-loader
                                                        :loading="createPostulantLoading"
                                                        :color="'#5e72e4'"
                                                        :margin="'5px'"
                                                        :size="10"
                                                        class="pt-2 pb-1 pl-2 text-center"
                                                        style="border-radius:5px; border:1px solid #5e72e4"
                                                    />

                                                    <b-form-select
                                                        v-model="partner_two.attributes.voucher_type"
                                                        :options="voucherTypeOptions"
                                                        :state="showInputStatus('partner_two_voucher_type')"
                                                        v-show="!createPostulantLoading"
                                                        @change="(e) => voucherTypeChange('pTwo', e)"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option value="" disabled>
                                                                -- Seleccione el tipo de pago --
                                                            </b-form-select-option>
                                                        </template>
                                                    </b-form-select>

                                                    <b-form-invalid-feedback
                                                        v-for="(inputError, i) in showInputErrors('partner_two_voucher_type')"
                                                        :key="`${i}-createVoucher-type`"
                                                    >
                                                        {{ inputError }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- code -->
                                                <b-form-group
                                                        label="Código de operación del voucher:" 
                                                        v-show="partner_two.attributes.showCode"
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-input
                                                            type="number"
                                                            placeholder="Ingrese el código de operación."
                                                            v-model="partner_two.attributes.voucher_code"
                                                            :state="showInputStatus('partner_two_voucher_code')"
                                                            v-show="!createPostulantLoading"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_two_voucher_code')"
                                                            :key="`${i}-createVoucher-code`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- date -->
                                                <b-form-group
                                                        label="Fecha de operación del voucher:" 
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-datepicker
                                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                            selected-variant="primary"
                                                            today-variant="naranja"
                                                            locale="es"

                                                            today-button
                                                            label-today-button="today"
                                                            today-button-variant="outline-naranja"
                                                            
                                                            reset-button
                                                            label-reset-button="reset"
                                                            reset-button-variant="outline-magenta"

                                                            close-button
                                                            label-close-button="ok"
                                                            close-button-variant="primary"

                                                            v-model="partner_two.attributes.voucher_date"
                                                            :state="showInputStatus('partner_two_voucher_date')"
                                                            v-if="!createPostulantLoading"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_two_voucher_date')"
                                                            :key="`${i}-createVoucher-date`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- time -->
                                                <b-form-group
                                                        label="Hora de operación del voucher:"
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-timepicker
                                                            locale="en"

                                                            now-button
                                                            label-now-button="now"
                                                            now-button-variant="outline-naranja"

                                                            reset-button
                                                            label-reset-button="reset"
                                                            reset-button-variant="outline-magenta"

                                                            close-button
                                                            label-close-button="ok"
                                                            close-button-variant="primary"

                                                            show-seconds
                                                            v-model="partner_two.attributes.voucher_time"
                                                            :state="showInputStatus('partner_two_voucher_time')"
                                                            v-if="!createPostulantLoading"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_two_voucher_time')"
                                                            :key="`${i}-createVoucher-time`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>

                                                <!-- description -->
                                                <b-form-group
                                                        label="Descripción:" 
                                                    >
                                                        <pulse-loader
                                                            :loading="createPostulantLoading"
                                                            :color="'#5e72e4'"
                                                            :margin="'5px'"
                                                            :size="10"
                                                            class="pt-2 pb-1 pl-2 text-center"
                                                            style="border-radius:5px; border:1px solid #5e72e4"
                                                        />

                                                        <b-form-textarea 
                                                            placeholder="Ingrese una descripción (opcional)."
                                                            v-model="partner_two.attributes.voucher_description"
                                                            :state="showInputStatus('partner_two_voucher_description')"
                                                            v-show="!createPostulantLoading"
                                                            rows="3"
                                                            max-rows="6"
                                                        />

                                                        <b-form-invalid-feedback
                                                            v-for="(inputError, i) in showInputErrors('partner_two_voucher_description')"
                                                            :key="`${i}-updateVoucher-voucher_description`"
                                                        >
                                                            {{ inputError }}
                                                        </b-form-invalid-feedback>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </b-col>
                            </b-row>

                            <!-- btn create -->
                            <b-form-row class="justify-content-center">
                                <b-col cols="12" sm="auto">
                                    <b-button class="w-100 my-2" variant="primary" type="submit" size="lg" :disabled="createPostulantLoading" >CREAR POSTULANTE</b-button>
                                </b-col>
                            </b-form-row>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>

        <!-- Control buttons-->
        <div class="text-center">
            <b-button-group class="my-4">
                <b-button @click="tabIndex--" size="sm" variant="sponsor-interview-3" :disabled="tabIndex == 0"><b-icon icon="chevron-left" /></b-button>
                <b-button @click="tabIndex++" size="sm" variant="sponsor-interview-3" :disabled="tabIndex == 2"><b-icon icon="chevron-right" /></b-button>
            </b-button-group>
        </div>

        <!-- MODAL SEARCH -->
        <b-modal
            id="modal-search-users"
            centered
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            size="lg"
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">BUSCAR USUARIOS</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalSearchUsersClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <!-- search user -->
                    <b-form @submit.prevent="searchUser">
                        <b-form-row>
                            <b-col cols="10">
                                <b-form-group>
                                    <pulse-loader
                                        :loading="loadingTableData"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />
                                    
                                    <b-form-input
                                        type="text"
                                        placeholder="Búsqueda por nombres o dni."
                                        v-model="inputToSearch"
                                        :state="showSearchInputStatus('input_to_search')"
                                        v-show="!loadingTableData"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showSearchInputErrors('input_to_search')" :key="`${i}-serchUsers`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <b-col cols="2">
                                <b-form-group>
                                    <b-button
                                        class="w-100 py-auto px-0"
                                        variant="primary"
                                        type="submit"
                                    ><b-icon icon="search" class="m-0 p-0"/></b-button>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-form>

                    <!-- laodingTableData -->
                    <div style="height:300px;" v-show="loadingTableData">
                        <pulse-loader
                            :color="'#5e72e4'"
                            :margin="'10px'"
                            :size="20"
                            style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                    </div>

                    <!-- card users table -->
                    <b-card no-body>
                        <!-- users table-->
                        <div class="table-responsive mb-0" v-show="!loadingTableData && !searchFailed">
                            <base-table class="table align-items-center table-flush"
                                thead-classes="thead-light"
                                tbody-classes="list"
                                :data="tableData"
                            >
                                <template slot="columns">
                                    <th></th>
                                    <th>Nombres</th>
                                    <th>documento</th>
                                    <th>correo</th>
                                </template>

                                <template slot-scope="{row}">
                                    <td class="text-center">
                                        <b-btn 
                                            variant="outline-primary"
                                            size="sm"
                                            @click="partnerChoose(row)"
                                        ><b-icon icon="grid-fill" class="m-0 p-0"/>
                                        </b-btn>
                                    </td>
                                    <td class="text-uppercase">
                                        <span class="text-uppercase">{{ row.attributes.last_name}} </span>
                                        <span class="text-capitalize">{{ row.attributes.name}}</span>
                                    </td>
                                    <td>{{ row.attributes.document_number}}</td>
                                    <td>{{ row.attributes.email}}</td>
                                </template>
                            </base-table>
                        </div>
                    </b-card>

                    <!-- pagination -->
                    <div class="card-footer border-0" v-show="!loadingTableData && !searchFailed">
                        <base-pagination
                            class="mb-0"
                            align="center"
                            :pageCount="tableMeta.last_page" 
                            :perPage="tableMeta.per_page"
                            :value="tableMeta.current_page"
                            @input="paginationCLiked"
                        />
                    </div>
                </b-container>
            </template>
        </b-modal>
    </b-form>
</template>

<script>
import { registerPostulantEnglishPromotion } from '@/api/Postulant/EnglishPromotion'
import { getAllProgramSeasons } from '@/api/ProgramSeason/ProgramSeason'
import { getAllActiveCulturalAdvisorsByProgram } from '@/api/CulturalAdvisor/CulturalAdvisor'
import { usersSearch } from '@/api/User/UserSearch'

import swal from 'sweetalert'

export default {
    name: 'PostulantEnglishPromotionComponent',
    props: {
        postulantCreateUI: {
            type: String,
            required: true
        },
        postulantListRoute: {
            type: String,
            required: true
        },
    },
    data: () => ({
        tabIndex: 0,

        //
        inputToSearch: '',
        initialSearchInputValue: true,
        inputToSearchErrors: {},
        searchFailed: true,
        searchPartnerSelected: '',
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        programSeasonData: [],
        getProgramSeasonOptionsLoading: false,
        //
        partner_one: {
            attributes: {
                user_id: '',
                //
                name: '',
                last_name: '',
                document_type: 'dni',
                document_number: '',
                phone_number: '',
                email: '',
                postulant_skype: '',
                postulant_profile: '',
                description: '', 
                hasBeenPostulant: null,
                postulant_id: '',
                // programSeason
                programSeasonOptions: [],
                programSeasonSelected: '',
                // culturalAdvisor
                culturalAdvisorOptions: [],
                culturalAdvisorSelected: '',
                getCulturalAdvisorLoading: false,
                // voucher
                showCode: true,
                preview_image: '/img/theme/voucher_info.jpg',
                //
                voucher_amount: '',
                voucher_type: '',
                voucher_code: '',
                voucher_image: null,
                voucher_date: '',
                voucher_time: '',
                voucher_description: '',
            }
        },
        //
        partner_one_selected: false,
        partner_two: {
            attributes: {
                user_id: '',
                //
                name: '',
                last_name: '',
                document_type: 'dni',
                document_number: '',
                phone_number: '',
                email: '',
                postulant_skype: '',
                postulant_profile: '',
                description: '', 
                hasBeenPostulant: null,
                postulant_id: '',
                // programSeason
                programSeasonOptions: [],
                programSeasonSelected: '',
                // culturalAdvisor
                culturalAdvisorOptions: [],
                culturalAdvisorSelected: '',
                getCulturalAdvisorLoading: false,
                // voucher
                showCode: true,
                preview_image: '/img/theme/voucher_info.jpg',
                //
                voucher_amount: '',
                voucher_type: '',
                voucher_code: '',
                voucher_image: null,
                voucher_date: '',
                voucher_time: '',
                voucher_description: '',
            }
        },
        partner_two_selected: false,
        // payment
        payment_count: '',
        paymentCountOptions: [{ value: 'one', text: 'Uno' }, { value: 'two', text: 'Dos' } ],
        payment_own: '',
        paymentOwnOptions: [], // [{ value: 'partner_one', text: 'Primer postulante' }, { value: 'partner_two', text: 'Segundo postulante' }, { value: 'both', text: 'Ambos' } ], // primero debe escoger la cantidad pagos
        //
        partnerOnePayment: false,
        partnerTwoPayment: false,
        // 
        voucher_concept: 'Entrevista de Inglés',        // solo como información en la interfaz
        createPostulantLoading: false,
        //
        documentTypeOptions: [
            { value: 'extranjeria', text: 'carnet de extranjería' },
            { value: 'dni', text: 'DNI' }
        ],
        //
        voucherTypeOptions: [
            { value: 'pago normal', text: 'Pago Normal' },
            { value: 'pago por aplicativo', text: 'Pago por Yape o Plin' },
            { value: 'nota de crédito', text: 'Nota de crédito' },
        ],
        // showCode: true,
        // preview_image: '/img/theme/voucher_info.jpg',
        //
        initialInputValues: true,
        inputErrors: {},
    }),

    created () {
        this.getProgramSeasonsData()
    },

    computed: {
        salesAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-ventas')
        },
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        }
    },

    methods: {
    
        // SEARCH USER MODAL METHODS
        modalSearchUsersClick(pPartner) {
            this.searchPartnerSelected = pPartner
            this.$bvModal.show('modal-search-users')
        },

        modalSearchUsersClose() {
            this.$bvModal.hide('modal-search-users')
            this.clearSearhInputsAndStates()
        },

        searchUser (pPage = 1) {
            this.initialSearchInputValue = false
            this.loadingTableData = true
            this.inputToSearchErrors = {}

            let SearchFormData = new FormData()
            SearchFormData.append('input_to_search', this.inputToSearch)

            usersSearch(pPage, SearchFormData)
                .then(({data}) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                    this.searchFailed = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputToSearchErrors = err.response.data.errors
                        this.searchFailed = true
                    }
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        partnerChoose(pRow) {
            let PartnerRow
            if(this.searchPartnerSelected == 'pOne') {
                PartnerRow = this.partner_one
                this.partner_one_selected = true
            }

            if(this.searchPartnerSelected == 'pTwo') {
                PartnerRow = this.partner_two
                this.partner_two_selected = true
            }

            PartnerRow.attributes.user_id = pRow.attributes.id

            PartnerRow.attributes.name = pRow.attributes.name
            PartnerRow.attributes.last_name = pRow.attributes.last_name
            PartnerRow.attributes.document_type = pRow.attributes.document_type
            PartnerRow.attributes.document_number = pRow.attributes.document_number
            PartnerRow.attributes.phone_number = pRow.attributes.phone_number
            PartnerRow.attributes.email = pRow.attributes.email
            PartnerRow.attributes.postulant_skype = pRow.attributes.postulant_skype
            PartnerRow.attributes.postulant_profile = pRow.attributes.postulant_profile
            PartnerRow.attributes.hasBeenPostulant = pRow.attributes.hasBeenPostulant
            PartnerRow.attributes.postulant_id = pRow.attributes.postulant_id

            this.modalSearchUsersClose()
        },

        clearPartnerData(pPartner) {
            let PartnerClear
            if(pPartner == 'pOne') {
                PartnerClear = this.partner_one
                this.partner_one_selected = false
            }

            if(pPartner == 'pTwo') {
                PartnerClear = this.partner_two
                this.partner_two_selected = false
            }

            PartnerClear.attributes.user_id = ''

            PartnerClear.attributes.name = ''
            PartnerClear.attributes.last_name = ''
            PartnerClear.attributes.document_type = 'dni'
            PartnerClear.attributes.document_number = ''
            PartnerClear.attributes.phone_number = ''
            PartnerClear.attributes.email = ''
            PartnerClear.attributes.postulant_skype = ''
            PartnerClear.attributes.postulant_profile = ''
            PartnerClear.attributes.hasBeenPostulant = null
            PartnerClear.attributes.postulant_id = ''

        },

        showSearchInputErrors (pInput) {
            if (Object.keys(this.inputToSearchErrors).includes(pInput)) return this.inputToSearchErrors[pInput]
            else return []
        },

        showSearchInputStatus (pInput) {
            if (this.initialSearchInputValue) return null 
            else if (Object.keys(this.inputToSearchErrors).includes(pInput)) return false
            else return true
        },

        clearSearhInputsAndStates() {
            this.inputToSearch = ''
            this.inputToSearchErrors = {}
            this.searchFailed = true
            this.searchPartnerSelected = ''
            this.tableData
            this.tableData = []
            this.tableMeta = {}
        },

        paginationCLiked (e) {
            this.searchUser(e)
        },
        // END SEARCH USER MODAL METHODS

        // PAYMENT METHODS
        paymentCountChange(e) {
            this.payment_own = ''
            this.partnerOnePayment = false
            this.partnerTwoPayment = false
            //
            if(e == 'one') this.paymentOwnOptions = [{ value: 'partner_one', text: 'Primer postulante' }, { value: 'partner_two', text: 'Segundo postulante' } ]
            if(e == 'two') this.paymentOwnOptions = [{ value: 'both', text: 'Ambos'} ]
        },
        paymentOwnChange(e) {
            this.partnerOnePayment = false
            this.partnerTwoPayment = false
            //
            if(e == 'partner_one') this.partnerOnePayment = true
            if(e == 'partner_two') this.partnerTwoPayment = true
            if(e == 'both') {
                this.partnerOnePayment = true
                this.partnerTwoPayment = true
            }
        },


        // CREATE POSTULANT METHODS
        createPostulant () {
            this.inputErrors = {}
            this.initialInputValues = false
            this.createPostulantLoading = true

            //
            let IdSalesAdvisor = this.salesAdvisorPermissionData ? this.salesAdvisorPermissionData.attributes.typeAdvisor_id : ''          // ok
            let IdCulturalAdvisor = this.culturalAdvisorPermissionData ? this.culturalAdvisorPermissionData.attributes.typeAdvisor_id  : ''  // ok

            let EnglishPromotionForm = new FormData()

            if (this.postulantCreateUI == 'sa') EnglishPromotionForm.append('register','sales_advisor')
            if (this.postulantCreateUI == 'ca') EnglishPromotionForm.append('register','cultural_advisor')

            /** PARTNER ONE */
            /* EXISTING USER */
            if (this.partner_one_selected) {
                EnglishPromotionForm.append('partner_one_user_id', this.partner_one.attributes.user_id)
                EnglishPromotionForm.append('partner_one_user_type', 'existing')
                EnglishPromotionForm.append('partner_one_existingPostulant', this.partner_one.attributes.hasBeenPostulant ? 'si' : 'no')

                if (this.partner_one.attributes.hasBeenPostulant) {
                    EnglishPromotionForm.append('partner_one_programSeason_code', `${this.partner_one.attributes.postulant_id}-${this.partner_one.attributes.programSeasonSelected}`) // IDpostulant-IDprogramSeason
                } else {
                    // postulant
                    EnglishPromotionForm.append('partner_one_skype', this.partner_one.attributes.postulant_skype)
                    EnglishPromotionForm.append('partner_one_profile', this.partner_one.attributes.postulant_profile)
                    EnglishPromotionForm.append('partner_one_description', `postulante ${this.partner_one.attributes.lastName}`)
                }
            }
            /* end EXISTING USER */

            /* NEW USER */
            if (!this.partner_one_selected) {
                EnglishPromotionForm.append('partner_one_user_type', 'new')
                // user
                EnglishPromotionForm.append('partner_one_name', this.partner_one.attributes.name)
                EnglishPromotionForm.append('partner_one_last_name', this.partner_one.attributes.last_name)
                EnglishPromotionForm.append('partner_one_document_type', this.partner_one.attributes.document_type)
                EnglishPromotionForm.append('partner_one_document_number', this.partner_one.attributes.document_number)
                EnglishPromotionForm.append('partner_one_phone_number', this.partner_one.attributes.phone_number)
                EnglishPromotionForm.append('partner_one_email', this.partner_one.attributes.email)
                // postulant
                EnglishPromotionForm.append('partner_one_skype', this.partner_one.attributes.postulant_skype)
                EnglishPromotionForm.append('partner_one_profile', this.partner_one.attributes.postulant_profile)
                EnglishPromotionForm.append('partner_one_description', `postulante ${this.partner_one.attributes.last_name}`)
            }
            /* end NEW USER */

            // for new programSeasonPostulant
            EnglishPromotionForm.append('partner_one_programSeason_id', this.partner_one.attributes.programSeasonSelected)
            if (this.postulantCreateUI == 'sa') {
                EnglishPromotionForm.append('partner_one_salesAdvisor_id', IdSalesAdvisor)                  //
                EnglishPromotionForm.append('partner_one_culturalAdvisorAssigned_id', this.partner_one.attributes.culturalAdvisorSelected)   //
            }
            if (this.postulantCreateUI == 'ca') {
                EnglishPromotionForm.append('partner_one_culturalAdvisor_id', IdCulturalAdvisor)            //
                EnglishPromotionForm.append('partner_one_culturalAdvisorAssigned_id', IdCulturalAdvisor)    //
            }
            EnglishPromotionForm.append('partner_one_programSeason_description', this.partner_one.attributes.description)
            /** END PARTNER ONE */


            /** PARTNER TWO */
            /* EXISTING USER */
            if (this.partner_two_selected) {
                EnglishPromotionForm.append('partner_two_user_id', this.partner_two.attributes.user_id)
                EnglishPromotionForm.append('partner_two_user_type', 'existing')
                EnglishPromotionForm.append('partner_two_existingPostulant', this.partner_two.attributes.hasBeenPostulant ? 'si' : 'no')

                if (this.partner_two.attributes.hasBeenPostulant) {
                    EnglishPromotionForm.append('partner_two_programSeason_code', `${this.partner_two.attributes.postulant_id}-${this.partner_two.attributes.programSeasonSelected}`) // IDpostulant-IDprogramSeason
                } else {
                    // postulant
                    EnglishPromotionForm.append('partner_two_skype', this.partner_two.attributes.skype)
                    EnglishPromotionForm.append('partner_two_profile', this.partner_two.attributes.profile)
                    EnglishPromotionForm.append('partner_two_description', `postulante ${this.partner_two.attributes.lastName}`)
                }
            }
            /* end EXISTING USER */

            /* NEW USER */
            if (!this.partner_two_selected) {
                EnglishPromotionForm.append('partner_two_user_type', 'new')
                // user
                EnglishPromotionForm.append('partner_two_name', this.partner_two.attributes.name)
                EnglishPromotionForm.append('partner_two_last_name', this.partner_two.attributes.last_name)
                EnglishPromotionForm.append('partner_two_document_type', this.partner_two.attributes.document_type)
                EnglishPromotionForm.append('partner_two_document_number', this.partner_two.attributes.document_number)
                EnglishPromotionForm.append('partner_two_phone_number', this.partner_two.attributes.phone_number)
                EnglishPromotionForm.append('partner_two_email', this.partner_two.attributes.email)
                // postulant
                EnglishPromotionForm.append('partner_two_skype', this.partner_two.attributes.postulant_skype)
                EnglishPromotionForm.append('partner_two_profile', this.partner_two.attributes.postulant_profile)
                EnglishPromotionForm.append('partner_two_description', `postulante ${this.partner_two.attributes.last_name}`)
            }
            /* end NEW USER */

            // for new programSeasonPostulant
            EnglishPromotionForm.append('partner_two_programSeason_id', this.partner_two.attributes.programSeasonSelected)
            if (this.postulantCreateUI == 'sa') {
                EnglishPromotionForm.append('partner_two_salesAdvisor_id', IdSalesAdvisor)                  //
                EnglishPromotionForm.append('partner_two_culturalAdvisorAssigned_id', this.partner_two.attributes.culturalAdvisorSelected)   //
            }
            if (this.postulantCreateUI == 'ca') {
                EnglishPromotionForm.append('partner_two_culturalAdvisor_id', IdCulturalAdvisor)            //
                EnglishPromotionForm.append('partner_two_culturalAdvisorAssigned_id', IdCulturalAdvisor)    //
            }
            EnglishPromotionForm.append('partner_two_programSeason_description', this.partner_two.attributes.description)
            /** END PARTNER TWO */


            /** PAYMENT */
            EnglishPromotionForm.append('payment_count', this.payment_count)
            EnglishPromotionForm.append('payment_own', this.payment_own)

            if(this.payment_count == 'one' ) {
                if (this.partnerOnePayment) {
                    // partner one
                    EnglishPromotionForm.append('partner_one_voucher_amount', this.partner_one.attributes.voucher_amount)
                    EnglishPromotionForm.append('partner_one_voucher_type', this.partner_one.attributes.voucher_type)
                    EnglishPromotionForm.append('partner_one_voucher_code', this.partner_one.attributes.showCode ? this.partner_one.attributes.voucher_code : '')
                    EnglishPromotionForm.append('partner_one_voucher_image', this.partner_one.attributes.voucher_image)
                    EnglishPromotionForm.append('partner_one_voucher_date', this.partner_one.attributes.voucher_date)
                    EnglishPromotionForm.append('partner_one_voucher_time', this.partner_one.attributes.voucher_time)
                    EnglishPromotionForm.append('partner_one_voucher_description', this.partner_one.attributes.voucher_description)
                } else if(this.partnerTwoPayment) {
                    // partner two
                    EnglishPromotionForm.append('partner_two_voucher_amount', this.partner_two.attributes.voucher_amount)
                    EnglishPromotionForm.append('partner_two_voucher_type', this.partner_two.attributes.voucher_type)
                    EnglishPromotionForm.append('partner_two_voucher_code', this.partner_two.attributes.showCode ? this.partner_two.attributes.voucher_code : '')
                    EnglishPromotionForm.append('partner_two_voucher_image', this.partner_two.attributes.voucher_image)
                    EnglishPromotionForm.append('partner_two_voucher_date', this.partner_two.attributes.voucher_date)
                    EnglishPromotionForm.append('partner_two_voucher_time', this.partner_two.attributes.voucher_time)
                    EnglishPromotionForm.append('partner_two_voucher_description', this.partner_two.attributes.voucher_description)
                }
            }

            if(this.payment_count == 'two') {
                // partner one
                EnglishPromotionForm.append('partner_one_voucher_amount', this.partner_one.attributes.voucher_amount)
                EnglishPromotionForm.append('partner_one_voucher_type', this.partner_one.attributes.voucher_type)
                EnglishPromotionForm.append('partner_one_voucher_code', this.partner_one.attributes.showCode ? this.partner_one.attributes.voucher_code : '')
                EnglishPromotionForm.append('partner_one_voucher_image', this.partner_one.attributes.voucher_image)
                EnglishPromotionForm.append('partner_one_voucher_date', this.partner_one.attributes.voucher_date)
                EnglishPromotionForm.append('partner_one_voucher_time', this.partner_one.attributes.voucher_time)
                EnglishPromotionForm.append('partner_one_voucher_description', this.partner_one.attributes.voucher_description)

                // partner two
                EnglishPromotionForm.append('partner_two_voucher_amount', this.partner_two.attributes.voucher_amount)
                EnglishPromotionForm.append('partner_two_voucher_type', this.partner_two.attributes.voucher_type)
                EnglishPromotionForm.append('partner_two_voucher_code', this.partner_two.attributes.showCode ? this.partner_two.attributes.voucher_code : '')
                EnglishPromotionForm.append('partner_two_voucher_image', this.partner_two.attributes.voucher_image)
                EnglishPromotionForm.append('partner_two_voucher_date', this.partner_two.attributes.voucher_date)
                EnglishPromotionForm.append('partner_two_voucher_time', this.partner_two.attributes.voucher_time)
                EnglishPromotionForm.append('partner_two_voucher_description', this.partner_two.attributes.voucher_description)
            }

            registerPostulantEnglishPromotion(EnglishPromotionForm)
                .then(({ data }) => {
                    console.log(data);
                     swal('Creación correcta!', 'Nuevo postulante creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: this.postulantListRoute})
                                    break;

                                default:
                                    console.log('reg-post-engl-prom-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createPostulantLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },

        // programSeason
        getProgramSeasonsData () {
            this.getProgramSeasonOptionsLoading = true

            getAllProgramSeasons()
                .then(({data}) => {
                    this.programSeasonData = data.data
                    this.partner_one.attributes.programSeasonOptions = data.data
                                                    .filter(el => el.attributes.status == 'activado')
                                                    .map(el => ({ value: el.attributes.id, text: `${el.attributes.program_name} * ${el.attributes.season_name}` }))
                    this.partner_two.attributes.programSeasonOptions = data.data
                                                    .filter(el => el.attributes.status == 'activado')
                                                    .map(el => ({ value: el.attributes.id, text: `${el.attributes.program_name} * ${el.attributes.season_name}` }))
                })
                .finally(() => {
                    this.getProgramSeasonOptionsLoading = false
                })
        },

        // culturalAdvisor
        getCulturalAdvisor (pPartner, event) {
            let Partner
            if(pPartner == 'pOne') Partner = this.partner_one
            if(pPartner == 'pTwo') Partner = this.partner_two

            Partner.attributes.getCulturalAdvisorLoading = true
            Partner.attributes.culturalAdvisorSelected = ''

            let programId
            for (const el of this.programSeasonData) {
                if(parseInt(el.id) == parseInt(event)) {
                    switch (el.attributes.program_id.toString()) {
                        case '1':
                            programId = 1
                            break
                        case '2':
                            programId = 2
                            break
                        case '3':
                            programId = 3
                            break
                        case '4':
                            programId = 4
                            break
                        case '5':
                            programId = 5
                            break
                        default:
                            console.log('programIdByProgramSeasonID: '+ event  + 'breack');
                            break;
                    }
                    break;
                }
            }

            getAllActiveCulturalAdvisorsByProgram(programId)
                .then(({ data }) => {
                    Partner.attributes.culturalAdvisorOptions = data.data
                                                    .filter(el => el.attributes.status == 'activado')
                                                    .map(el => ({ value: el.attributes.id, text: el.attributes.employee_name }))
                })
                .finally(() => {
                    Partner.attributes.getCulturalAdvisorLoading = false
                })
        },

        // voucher
        voucherTypeChange (pPartner, event) {
            let Partner
            if(pPartner == 'pOne') Partner = this.partner_one
            if(pPartner == 'pTwo') Partner = this.partner_two
            //
            if(event == 'pago normal' || event == 'nota de crédito') Partner.attributes.showCode = true
            else Partner.attributes.showCode = false
        },

        showImagePreview (pPartner, event) {
            let Partner
            if(pPartner == 'pOne') Partner = this.partner_one
            if(pPartner == 'pTwo') Partner = this.partner_two
            //
            if (event.target.files.length >= 1) {
                // capturar img del input
                let image = event.target.files[0]
                //crear reader
                let reader = new FileReader()
                // código al cargar la img
                reader.onload = (ee) => {
                    Partner.attributes.preview_image = ee.target.result
                }
                // asignar la img
                reader.readAsDataURL(image)
            }
        },
    },
}
</script>

<style scoped>
.voucher-preview {
    border:1px solid rgba(0, 0, 0, 0.15);
}
</style>