<template>
    <!-- create postulant with existing user -->
    <div>
        <!-- search user -->
        <b-form @submit.prevent="searchUser">
            <b-form-row>
                <b-col cols="10">
                    <b-form-group>
                        <pulse-loader
                            :loading="loadingTableData"
                            :color="'#5e72e4'"
                            :margin="'5px'"
                            :size="10"
                            class="pt-2 pb-1 pl-2 text-center"
                            style="border-radius:5px; border:1px solid #5e72e4"
                        />
                        
                        <b-form-input
                            type="text"
                            placeholder="Búsqueda por nombres o dni."
                            v-model="inputToSearch"
                            :state="showInputStatus('input_to_search')"
                            v-show="!loadingTableData"
                        />

                        <b-form-invalid-feedback v-for="(inputError, i) in showInputErrors('input_to_search')" :key="`${i}-serchUsers`">
                            {{ inputError }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>

                <b-col cols="2">
                    <b-form-group>
                        <b-button
                            class="w-100 py-auto px-0"
                            variant="primary"
                            type="submit"
                        ><b-icon icon="search" class="m-0 p-0"/></b-button>
                    </b-form-group>
                </b-col>
            </b-form-row>
        </b-form>

        <!-- laodingTableData -->
        <div style="height:300px;" v-show="loadingTableData">
            <pulse-loader
                :color="'#5e72e4'"
                :margin="'10px'"
                :size="20"
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
        </div>

        <!-- card users table -->
        <b-card no-body>
            <!-- users table-->
            <div class="table-responsive mb-0" v-show="!loadingTableData && !searchFailed">
                <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                >
                    <template slot="columns">
                        <th></th>
                        <th>Nombres</th>
                        <th>documento</th>
                        <th>correo</th>
                    </template>

                    <template slot-scope="{row}">
                        <td class="text-center">
                            <b-btn 
                                variant="outline-primary"
                                size="sm"
                                @click="modalCreateProgramSeasonClick(row)"
                            ><b-icon icon="grid-fill" class="m-0 p-0"/>
                            </b-btn>
                        </td>
                        <td class="text-uppercase">
                            <span class="text-uppercase">{{ row.attributes.last_name}} </span>
                            <span class="text-capitalize">{{ row.attributes.name}}</span>
                        </td>
                        <td>{{ row.attributes.document_number}}</td>
                        <td>{{ row.attributes.email}}</td>
                    </template>
                </base-table>
            </div>
        </b-card>

        <!-- pagination -->
        <div class="card-footer border-0" v-show="!loadingTableData && !searchFailed">
            <base-pagination
                class="mb-0"
                align="center"
                :pageCount="tableMeta.last_page" 
                :perPage="tableMeta.per_page"
                :value="tableMeta.current_page"
                @input="paginationCLiked"
            />
        </div>

        <!--
            MODAL DETAILS
        -->
        <b-modal
            id="modal-details"
            centered
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            size="lg"
        >
            <!-- modal header -->
            <template #modal-header="{ close }">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0">CREAR POSTULANTE</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="() => {
                                    clearInputsAndStates()
                                    close()
                                }"
                            >
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal main content -->
            <template #default>
                <b-container>
                    <b-form @submit.prevent="createPostulant">
                        <b-form-row class="my-2">
                            <!-- nombres -->
                            <b-col cols="12" xl="5">
                                <b-form-group
                                    label="Nombres"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="text"
                                        placeholder="Ingrese los nombres."
                                        v-model="userSelected.attributes.name"
                                        :state="showModalInputStatus('name')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('name')" :key="`${i}-createPostulant-name`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- apellidos -->
                            <b-col cols="12" xl="7">
                                <b-form-group
                                    label="Apellidos"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="text"
                                        placeholder="Ingrese los apellidos."
                                        v-model="userSelected.attributes.last_name"
                                        :state="showModalInputStatus('last_name')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('last_name')" :key="`${i}-createPostulant-lastName`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="my-2">
                            <!-- tipo-doc -->
                            <b-col cols="12" xl="5">
                                <b-form-group
                                    label="Tipo de documento"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-select
                                        disabled
                                        v-model="userSelected.attributes.document_type"
                                        :options="documentTypeOptions"
                                        :state="showModalInputStatus('document_type')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    >
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('document_type')" :key="`${i}-createPostulant-documentType`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                        <!-- num-doc -->
                            <b-col cols="12" xl="7">
                                <b-form-group
                                    label="Número de documento"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="number"
                                        placeholder="Ingrese el número de documento."
                                        v-model="userSelected.attributes.document_number"
                                        :state="showModalInputStatus('document_number')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('document_number')" :key="`${i}-createPostulant-documentNumber`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- celular -->
                        <b-form-row class="my-2">
                            <b-col cols="12" xl="5">
                                <b-form-group
                                    label="Número de celular"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="number"
                                        placeholder="Ingrese el número de celular"
                                        v-model="userSelected.attributes.phone_number"
                                        :state="showModalInputStatus('phone_number')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('phone_number')" :key="`${i}-createPostulant-phoneNumber`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                        <!-- correo -->
                            <b-col cols="12" xl="7">
                                <b-form-group
                                    label="Correo"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="email"
                                        placeholder="Ingrese el correo."
                                        v-model="userSelected.attributes.email"
                                        :state="showModalInputStatus('email')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('email')" :key="`${i}-createPostulant-email`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- skype -->
                        <b-form-row class="my-2">
                            <b-col cols="12" xl="5">
                                <b-form-group
                                    label="Skype ID"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="text"
                                        v-model="userSelected.attributes.postulant_skype"
                                        :state="showModalInputStatus('skype')"
                                        v-show="!userSelected.createPostulantLoading && true && userSelected.attributes.hasBeenPostulant"
                                    />
                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el SKYPE ID."
                                        v-model="skype"
                                        :state="showModalInputStatus('skype')"
                                        v-show="!userSelected.createPostulantLoading && true && !userSelected.attributes.hasBeenPostulant"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('skype')" :key="`${i}-createPostulant-skype`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                        <!-- profile -->
                            <b-col cols="12" xl="7">
                                <b-form-group
                                    label="Link del perfil"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-input
                                        disabled
                                        type="text"
                                        v-model="userSelected.attributes.postulant_profile"
                                        :state="showModalInputStatus('profile')"
                                        v-show="!userSelected.createPostulantLoading && true && userSelected.attributes.hasBeenPostulant"
                                    />
                                    <b-form-input
                                        type="text"
                                        placeholder="Ingrese el link del perfil."
                                        v-model="profile"
                                        :state="showModalInputStatus('profile')"
                                        v-show="!userSelected.createPostulantLoading && true && !userSelected.attributes.hasBeenPostulant"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('profile')" :key="`${i}-createPostulant-profile`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <!-- descripción -->
                        <b-form-row class="my-2">
                            <b-col cols="12">
                                <b-form-group
                                    label="Descripción"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-textarea 
                                        placeholder="Ingrese una descripción (opcional)"
                                        v-model="programSeasonPostulantDescription"
                                        :state="showModalInputStatus('programSeason_description')"
                                        v-show="!userSelected.createPostulantLoading && true"
                                    />

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('programSeason_description')" :key="`${i}-createPostulant-description`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-form-row>

                        <b-form-row class="my-2">
                            <!-- programa * temporada -->
                            <b-col cols="12">
                                <b-form-group
                                    label="Programa - Temporada"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false || getProgramSeasonOptionsLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-select 
                                        :disabled="getCulturalAdvisorLoading"
                                        v-model="programSeasonSelected"
                                        :options="programSeasonOptions"
                                        :state="showModalInputStatus('programSeason_id') && showModalInputStatus('programSeason_code')"
                                        v-show="!userSelected.createPostulantLoading && true && !getProgramSeasonOptionsLoading"
                                        @change="getCulturalAdvisor"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione un programa * temporada --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('programSeason_id')" :key="`${i}-createPostulant-programSeason_id`">
                                         {{ inputError }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('programSeason_code')" :key="`${i}-createPostulant-programSeason_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>

                            <!-- culturalAdvisorAssigned -->
                            <!-- desactivado por que por que no necesita -->
                            <!-- <b-col cols="12" xl="7">
                                <b-form-group
                                    label="Asesor Cultural"
                                >
                                    <pulse-loader
                                        :loading="userSelected.createPostulantLoading || false || getCulturalAdvisorLoading"
                                        :color="'#5e72e4'"
                                        :margin="'5px'"
                                        :size="10"
                                        class="pt-2 pb-1 pl-2 text-center"
                                        style="border-radius:5px; border:1px solid #5e72e4"
                                    />

                                    <b-form-select 
                                        v-model="culturalAdvisorSelected"
                                        :options="culturalAdvisorOptions"
                                        :state="showModalInputStatus('programSeason_id')"
                                        v-show="!userSelected.createPostulantLoading && true && !getCulturalAdvisorLoading"
                                    >
                                        <template #first>
                                            <b-form-select-option value="" disabled>
                                                -- Seleccione un asesor cultural --
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>

                                    <b-form-invalid-feedback v-for="(inputError, i) in showModalInputErrors('programSeason_id')" :key="`${i}-createPostulant-programSeason_id`">
                                        {{ inputError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col> -->
                        </b-form-row>

                        <!-- voucher form -->
                        <b-card :border-variant="borderByProgram" v-show="programSeasonSelected ? true : false">
                            <b-form @submit.prevent="createVoucher" class="mt-4">
                                <b-form-row>
                                    <!-- voucher_concept -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Concepto del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="userSelected.createPostulantLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                disabled
                                                v-model="voucher_concept"
                                                :state="showModalInputStatus('voucher_concept')"
                                                v-show="!userSelected.createPostulantLoading && true"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('voucher_concept')"
                                                :key="`${i}-createVoucher-concept`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- amount -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Monto:" 
                                        >
                                            <pulse-loader
                                                :loading="userSelected.createPostulantLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                placeholder="Ingrese el monto de la entrevista."
                                                type="number"
                                                step="0.01"
                                                v-model="amount"
                                                :state="showModalInputStatus('amount')"
                                                v-show="!userSelected.createPostulantLoading && true"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('amount')"
                                                :key="`${i}-createVoucher-amount`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- image -->
                                <b-row  align-h="center">
                                    <!-- preview image -->
                                    <b-col cols="12" xl="6"  class="mb-4 d-block" >
                                        <b-img
                                            title="voucher"
                                            :src="preview_image"
                                            fluid
                                            class="voucher-preview"
                                            rounded=""
                                        ></b-img>
                                    </b-col>
                                    
                                    <!-- voucher-img -->
                                    <b-col cols="12" xl="6">
                                        <b-form-group
                                            label="Imagen del voucher:" 
                                        >
                                            <pulse-loader
                                                :loading="userSelected.createPostulantLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-file
                                                v-model="image"
                                                :state="showModalInputStatus('image')"
                                                v-show="!userSelected.createPostulantLoading && true"
                                                style="overflow:hidden;"
                                                @change="showImagePreview"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('image')"
                                                :key="`${i}-createVoucher-image`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- type -->
                                        <b-form-group
                                            label="Tipo de pago:" 
                                        >
                                            <pulse-loader
                                                :loading="userSelected.createPostulantLoading || false"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="type"
                                                :options="voucherTypeOptions"
                                                :state="showModalInputStatus('type')"
                                                v-show="!userSelected.createPostulantLoading && true"
                                                @change="voucherTypeChange"
                                            >
                                                <template #first>
                                                    <b-form-select-option value="" disabled>
                                                        -- Seleccione el tipo de pago --
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('type')"
                                                :key="`${i}-createVoucher-type`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- code -->
                                        <b-form-group
                                                label="Código de operación del voucher:" 
                                                v-show="showCode"
                                            >
                                                <pulse-loader
                                                    :loading="userSelected.createPostulantLoading || false"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-input
                                                    type="number"
                                                    placeholder="Ingrese el código de operación."
                                                    v-model="code"
                                                    :state="showModalInputStatus('code')"
                                                    v-show="!userSelected.createPostulantLoading && true"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('code')"
                                                    :key="`${i}-createVoucher-code`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- date -->
                                        <b-form-group
                                                label="Fecha de operación del voucher:" 
                                            >
                                                <pulse-loader
                                                    :loading="userSelected.createPostulantLoading || false"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-datepicker
                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                    selected-variant="primary"
                                                    today-variant="naranja"
                                                    locale="es"

                                                    today-button
                                                    label-today-button="today"
                                                    today-button-variant="outline-naranja"
                                                    
                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    v-model="date"
                                                    :state="showModalInputStatus('date')"
                                                    v-if="!userSelected.createPostulantLoading && true"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('date')"
                                                    :key="`${i}-createVoucher-date`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- time -->
                                        <b-form-group
                                                label="Hora de operación del voucher:"
                                            >
                                                <pulse-loader
                                                    :loading="userSelected.createPostulantLoading || false"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-timepicker
                                                    locale="en"

                                                    now-button
                                                    label-now-button="now"
                                                    now-button-variant="outline-naranja"

                                                    reset-button
                                                    label-reset-button="reset"
                                                    reset-button-variant="outline-magenta"

                                                    close-button
                                                    label-close-button="ok"
                                                    close-button-variant="primary"

                                                    show-seconds
                                                    v-model="time"
                                                    :state="showModalInputStatus('time')"
                                                    v-if="!userSelected.createPostulantLoading && true"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('time')"
                                                    :key="`${i}-createVoucher-time`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                        </b-form-group>

                                    <!-- description -->
                                        <b-form-group
                                                label="Descripción:" 
                                            >
                                                <pulse-loader
                                                    :loading="userSelected.createPostulantLoading || false"
                                                    :color="'#5e72e4'"
                                                    :margin="'5px'"
                                                    :size="10"
                                                    class="pt-2 pb-1 pl-2"
                                                    style="border-radius:5px; border:1px solid #5e72e4"
                                                />

                                                <b-form-textarea 
                                                    placeholder="Ingrese una descripción (opcional)."
                                                    v-model="voucher_description"
                                                    :state="showModalInputStatus('voucher_description')"
                                                    v-show="!userSelected.createPostulantLoading && true"
                                                    rows="3"
                                                    max-rows="6"
                                                />

                                                <b-form-invalid-feedback
                                                    v-for="(inputError, i) in showModalInputErrors('voucher_description')"
                                                    :key="`${i}-updateVoucher-voucher_description`"
                                                >
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>

                        <!-- btn crear -->
                        <b-form-row class="justify-content-center">
                            <b-col cols="12" sm="auto">
                                <b-button
                                    class="w-100 my-2"
                                    variant="primary"
                                    type="submit"
                                    size="lg"
                                    :disabled="userSelected.createPostulantLoading || false"
                                >CREAR POSTULANTE</b-button>
                            </b-col>
                        </b-form-row>
                    </b-form>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { getAllProgramSeasons } from '@/api/ProgramSeason/ProgramSeason'
import { usersSearch } from '@/api/User/UserSearch'
import { storeUserPostulant } from '@/api/User/UserPostulant'
//
import { getAllActiveCulturalAdvisorsByProgram } from '@/api/CulturalAdvisor/CulturalAdvisor'

import swal from 'sweetalert'
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'PostulantExistingUser',
    //components: { PulseLoader },
    data: () => ({
        //
        inputToSearch: '',
        inputToSearchErrors: {},
        searchFailed: true,
        //
        tableData: [],
        tableMeta: {},
        //
        initialInputValues: true,
        initialModalInputValues: true,
        loadingTableData: false,
        // Modal
        userSelected: {},
        hasBeenPostulant: null,
        postulantId: '',
        //
        skype: '',
        profile: '',
        programSeasonPostulantDescription: '',
        // voucher
        voucher_concept: 'Entrevista de Inglés',
        amount: '',
        type : '',
        code : '',
        image : null,
        date : '',
        time : '',
        voucher_description : '',
        // programSeason
        programSeasonData: [],
        programSeasonOptions: [],
        programSeasonSelected: '',
        programIDSelected: '',
        getProgramSeasonOptionsLoading: false,
        // culturalAdvisor
        culturalAdvisorOptions: [],
        culturalAdvisorSelected: '',
        getCulturalAdvisorLoading: false,
        //
        documentTypeOptions: [
            { value: 'extranjeria', text: 'carnet de extranjería' },
            { value: 'dni', text: 'DNI' }
        ],
        //
        voucherTypeOptions: [
            { value: 'pago normal', text: 'Pago Normal' },
            { value: 'pago por aplicativo', text: 'Pago por Yape o Plin' },
            { value: 'nota de crédito', text: 'Nota de crédito' },
        ],
        showCode: true,
        preview_image: '/img/theme/voucher_info.jpg',
        //
        inputModalErrors: {},
        //
    }),

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        },
        borderByProgram() {
            let borderColor
            for (const el of this.programSeasonData) {
                if(parseInt(el.id) == parseInt(this.programSeasonSelected)) {
                    switch (el.attributes.program_id.toString()) {
                        case '1':
                            borderColor = 'primary'
                            break
                        case '2':
                        case '3':
                            borderColor = 'cielo'
                            break
                        case '4':
                            borderColor = 'naranja'
                            break
                        case '5':
                            borderColor = 'green'
                            break
                        default:
                            console.log('borderByProgram psID:'+ this.programSeasonSelected + 'breack');
                            break;
                    }
                    break;
                } else {
                    borderColor = 'magenta'
                }
            }
            return borderColor
        }
    },

    methods: {
        // searchUserWithoutRolePostulant (pPage = 1) {
        searchUser (pPage = 1) {
            this.initialInputValues = false
            this.loadingTableData = true
            this.inputToSearchErrors = {}

            let SearchFormData = new FormData()
            SearchFormData.append('input_to_search', this.inputToSearch)

            usersSearch(pPage, SearchFormData)
                .then(({data}) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                    this.searchFailed = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputToSearchErrors = err.response.data.errors
                        this.searchFailed = true
                    }
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('request userSearch end');
                })
        },

        paginationCLiked (e) {
            this.searchUser(e)
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputToSearchErrors).includes(pInput)) return this.inputToSearchErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputToSearchErrors).includes(pInput)) return false
            else return true
        },

        // MODAL METHODS
        modalCreateProgramSeasonClick(pRow) {
            this.userSelected = pRow
            //
            for (const el of this.userSelected.attributes.user_roles) {
                if (el.attributes.role_name == 'postulante') {
                    this.hasBeenPostulant = true
                    this.postulantId = el.attributes.idToManage
                    break
                } else this.hasBeenPostulant = false
            }
            //
            this.getProgramSeasonsData()
            this.$bvModal.show('modal-details')
        },

        createPostulant () {
            this.initialModalInputValues = false
            this.inputModalErrors = {}
            this.$set(this.userSelected, "createPostulantLoading", true);

            //
            let IdCulturalAdvisor = this.culturalAdvisorPermissionData.attributes.typeAdvisor_id   // ok

            let PostulantFormData = new FormData()
            PostulantFormData.append('register','cultural_advisor')

            // existingPostulant
            let existing_postulant
            for (const el of this.userSelected.attributes.user_roles) {
                if (el.attributes.role_name == 'postulante') {
                    existing_postulant = 'si'
                    PostulantFormData.append('existingPostulant', 'si')
                    PostulantFormData.append('programSeason_code', `${el.attributes.idToManage}-${this.programSeasonSelected}`) // IDpostulant-IDprogramSeason
                    break
                } else existing_postulant = 'no'
            }
            // postulant
            if (existing_postulant == 'no') {
                PostulantFormData.append('existingPostulant', 'no')
                PostulantFormData.append('skype', this.skype)
                PostulantFormData.append('profile', this.profile)
                PostulantFormData.append('postulant_description', `postulante ${this.userSelected.attributes.last_name}`)
            }

            // programSeasonPostulant
            PostulantFormData.append('programSeason_id', this.programSeasonSelected)
            PostulantFormData.append('culturalAdvisor_id', IdCulturalAdvisor)
            PostulantFormData.append('culturalAdvisorAssigned_id', IdCulturalAdvisor)
            PostulantFormData.append('programSeason_description', this.programSeasonPostulantDescription)

            // postulantVoucher - entrevista de ingles
            PostulantFormData.append('amount', this.amount)
            PostulantFormData.append('type', this.type)

            if(this.showCode) PostulantFormData.append('code', this.code)
            else PostulantFormData.append('code', '')

            PostulantFormData.append('image', this.image)
            PostulantFormData.append('date', this.date)
            PostulantFormData.append('time', this.time)
            PostulantFormData.append('voucher_description', this.voucher_description)


            storeUserPostulant(PostulantFormData, this.userSelected.attributes.id)
                .then(() => {
                     swal('Creación correcta!', 'Nuevo postulante creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    // this.$bvModal.hide('modal-create-programSeason')
                                    this.$router.push({ name: 'employee-postulants-list-CA'})
                                    break;

                                default:
                                    console.log('postulantCreate-PostulantExistingUser-storeUserPostulant-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.inputModalErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.userSelected.createPostulantLoading = false
                    console.log('request create userPostulant end');
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.inputModalErrors).includes(pInput)) return this.inputModalErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null
            else if (Object.keys(this.inputModalErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalInputValues = true

            // this.userSelected = {},
            this.hasBeenPostulant = null,
            this.postulantId = '',
            //
            this.skype = '',
            this.profile = '',
            this.programSeasonPostulantDescription = '',
            // voucher
            this.voucher_concept = 'Entrevista de Inglés',
            this.amount = '',
            this.type = '',
            this.code = '',
            this.image = null,
            this.date = '',
            this.time = '',
            this.voucher_description = '',
            // programSeason
            this.programSeasonData = [],
            this.programSeasonOptions = [],
            this.programSeasonSelected = '',
            this.programIDSelected = '',
            this.getProgramSeasonOptionsLoading = false,
            // culturalAdvisor
            this.culturalAdvisorOptions = [],
            this.culturalAdvisorSelected = '',
            this.getCulturalAdvisorLoading = false,

            this.inputModalErrors = {}
        },

        // programSeason
        getProgramSeasonsData () {
            this.getProgramSeasonOptionsLoading = true

            getAllProgramSeasons()
                .then(({data}) => {
                    this.programSeasonData = data.data
                    this.programSeasonOptions = data.data
                                                    .filter(el => el.attributes.status == 'activado')
                                                    .map(el => ({ value: el.attributes.id, text: `${el.attributes.program_name} * ${el.attributes.season_name}` }))
                })
                .catch(err => {
                    console.log('getAllProgramSeasons error', err.response);
                })
                .finally(() => {
                    this.getProgramSeasonOptionsLoading = false
                })
        },

        // culturalAdvisor 
        getCulturalAdvisor (event) {
            this.getCulturalAdvisorLoading = true
            this.culturalAdvisorSelected = ''

            let programId
            for (const el of this.programSeasonData) {
                if(parseInt(el.id) == parseInt(event)) {
                    switch (el.attributes.program_id.toString()) {
                        case '1':
                            programId = 1
                            break
                        case '2':
                            programId = 2
                            break
                        case '3':
                            programId = 3
                            break
                        case '4':
                            programId = 4
                            break
                        case '5':
                            programId = 5
                            break
                        default:
                            console.log('programIdByProgramSeasonID: '+ event  + 'breack');
                            break;
                    }
                    break;
                }
            }

            getAllActiveCulturalAdvisorsByProgram(programId)
                .then(({ data }) => {
                    this.culturalAdvisorOptions = data.data.map(el => ({ value: el.attributes.id, text: el.attributes.employee_name }))
                })
                .catch(err => {
                    console.log('get all cultural advisor activate error :', err.response);
                })
                .finally(() => {
                    this.getCulturalAdvisorLoading = false
                })
        },

        // voucher
        voucherTypeChange (e) {
            if(e == 'pago normal' || e == 'nota de crédito') this.showCode = true
            else this.showCode = false
        },

        showImagePreview (e) {
            if (e.target.files.length >= 1) {
                // capturar img del input
                let image = e.target.files[0]
                //crear reader
                let reader = new FileReader()
                // código al cargar la img
                reader.onload = (ee) => {
                    this.preview_image = ee.target.result
                }
                // asignar la img
                reader.readAsDataURL(image)
            }
        },
    },
}
</script>

<style scoped>
.modal-user-data-icon:hover ~ .modal-user-data-id {
    transform: scale(1);
}
.modal-user-data {
    width: fit-content;
    border: 1px solid #5e72e4;
    border-radius:10px;
    text-transform: uppercase;
}
.modal-user-data-id {
    position: absolute;
    bottom: 80%;
    padding: 4px 8px;
    background: #000;
    border-radius: 5px;
    font-size: 10pt;
    color: white;
    opacity: .75;
    transition: .300s ease-in all;
    transform-origin: left bottom;
    transform: scale(0);
}
</style>