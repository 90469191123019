<template>
    <postulant-english-promotion-component
        :postulantCreateUI="postulant_create_UI"
        :postulantListRoute="postulant_list_route"
    ></postulant-english-promotion-component>
</template>

<script>
import PostulantEnglishPromotionComponent from '@/components/Employee/Postulant/GeneralAdvisor/PostulantCreate/PostulantEnglishPromotionComponent.vue'

export default {
    name: 'PostulantEnglishPromotion',
    components: { PostulantEnglishPromotionComponent },
    data: () => ({
        postulant_create_UI: 'ca',
        postulant_list_route: 'employee-postulants-list-CA'
    })
}
</script>
