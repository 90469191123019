<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button d-none d-sm-block">
                                LISTA DE POSTULANTES
                            </h3>

                            <h3 class="mb-0 text-primary text-button d-block d-sm-none">
                                POST.
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                                <pulse-loader
                                    :loading="getSeasonOptionsLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="8"
                                    class="pt-2 text-center"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-select
                                    class="text-center"
                                    size="sm"
                                    v-model="seasonSelected"
                                    :options="seasonOptions"
                                    v-show="!getSeasonOptionsLoading"
                                    @change="searchSalesAdvisorPostulant(1)"
                                />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchSalesAdvisorPostulant(1)">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col>
                                        <b-form-group class="mb-0">
                                            <b-button
                                                class="w-100"
                                                size="sm"
                                                variant="primary"
                                                type="submit"
                                            ><b-icon icon="search" class="m-0 p-0"/></b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>

                        <b-col cols="auto" class="mt-3 mt-sm-0">
                            <b-button size="sm" :variant="order_type != 'created' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('created')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-numeric-down-alt" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98z"/>
                                    <path d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'a-z' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('a-z')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                                    <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'z-a' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('z-a')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-alpha-down-alt" viewBox="0 0 16 16">
                                    <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V7z"/>
                                    <path fill-rule="evenodd" d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371h-1.781zm1.57-.785L11 9.688h-.047l-.652 2.156h1.351z"/>
                                    <path d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>
                
                <!-- table salesAdvisor.season.programSeasonPostulants -->
                <div class="table-responsive" v-if="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="alwaysShow details"></th>
                            <th class="alwaysShow name">Postulante</th>
                            <th>programa</th>
                            <th>temporada</th>
                            <th>Respons. Ventas</th>
                            <th>Respons. Cultural</th>

                            <!-- payments: entrevista -->
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>entrevista</div></th>
                            <!-- contrato -->
                            <th class="text-center bg-primary text-white"><div>contrato</div></th>
                            <!-- payments: primera cuota -->
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>primera cuota</div></th>
                            <th class="text-center bg-primary text-white"></th>

                            <!-- entrevista -->
                            <th class="text-center bg-magenta text-white"><div>primera</div><div>entrevista</div></th>
                            <th class="text-center bg-magenta text-white"><div>segunda</div><div>entrevista</div></th>
                            <th class="text-center bg-magenta text-white"></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <span v-if="order_type == 'created'">
                                    <!-- numeración descendente -->
                                    {{ tableMeta.total - ((tableMeta.per_page * (tableMeta.current_page - 1)) + (index + 1)) + 1 }}
                                </span>
                                <span v-else>{{ index + 1 }}</span>
                            </td>
                            <td class="alwaysShow details text-right">
                                <b-btn 
                                    :to="{
                                        name: 'employee-postulants-details-SA',
                                        params: { 'IdProgramSeasonPostulant': row.id }    //IdProgramSeasonPostulant
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>

                            <td class="alwaysShow name">{{ row.attributes.postulant_full_name }}</td>
                            <td>{{ row.attributes.program}}</td>
                            <td>{{ row.attributes.season}}</td>
                            <!-- SA -->
                            <td>{{ row.attributes.salesAdvisor_full_name }}</td>
                            <!-- CA -->
                            <td>{{ row.attributes.culturalAdvisorAssigned_full_name }}</td>

                            <!-- payments: entrevista -->
                            <td>
                                <postulant-payment-cell-component :row="row.attributes"/>
                            </td>

                            <!-- contrato: -->
                            <td class="text-center">
                                <b-iconstack font-scale="1.3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contract`">
                                    <b-iconstack font-scale="1.3">
                                        <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon stacked :icon="contract.attributes.status == 'valido' ? 'check' : 'x'" scale="1.6" variant="primary"></b-icon>
                                    </b-iconstack>
                                </div>
                            </td>
                            <!-- payments: primera cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'Primera Cuota'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>

                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'Primera Cuota'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-right">
                                <b-btn 
                                    :to="{
                                        name: 'employee-postulants-payments-vouchers-SA',
                                        params: { 'IdProgramSeasonPostulant': row.id }    //IdProgramSeasonPostulant
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>

                            <!-- entrevistas -->
                            <td>
                                <div class="d-flex align-items-center">
                                    <b-iconstack font-scale="1.3" class="mr-3">
                                        <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon stacked icon="square-fill" scale="1.1" variant="lighter" v-if="row.attributes.primeraEntrevista == null"></b-icon>
                                        <b-icon stacked icon="square-fill" scale="1.1" variant="yellow" v-else-if="row.attributes.primeraEntrevista.attributes.schedule_status == 'pendiente'"></b-icon>
                                        <b-icon stacked scale="1.6" variant="primary" v-else
                                            :icon="row.attributes.primeraEntrevista.attributes.interview_status == 'aprobado' ? 'check' : 'x'"
                                        ></b-icon>
                                    </b-iconstack>

                                   <span class="text-caption text-muted" v-if="row.attributes.primeraEntrevista != null && row.attributes.primeraEntrevista.attributes.interview_status != null">
                                        <div>{{ row.attributes.primeraEntrevista.attributes.interview_status }}</div>
                                        <div>{{ row.attributes.primeraEntrevista.attributes.nivel }}</div>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon stacked icon="square-fill" scale="1.1" variant="lighter" v-if="row.attributes.segundaEntrevista == null"></b-icon>
                                        <b-icon stacked icon="square-fill" scale="1.1" variant="yellow" v-else-if="row.attributes.segundaEntrevista.attributes.schedule_status == 'pendiente'"></b-icon>
                                        <b-icon stacked scale="1.6" variant="primary" v-else
                                            :icon="row.attributes.segundaEntrevista.attributes.interview_status == 'aprobado' ? 'check' : 'x'"
                                        ></b-icon>
                                    </b-iconstack>

                                    <span class="text-caption text-muted" v-if="row.attributes.segundaEntrevista != null && row.attributes.segundaEntrevista.attributes.interview_status != null">
                                        <div>{{ row.attributes.segundaEntrevista.attributes.interview_status }}</div>
                                        <div>{{ row.attributes.segundaEntrevista.attributes.nivel }}</div>
                                    </span>
                                </div>
                            </td>

                            <td class="text-right">
                                <b-btn 
                                    :to="{
                                        name: 'employee-postulants-interviews-SA',
                                        params: { 'IdProgramSeasonPostulant': row.id }    //IdProgramSeasonPostulant
                                    }"
                                    variant="outline-magenta"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" variant="magenta"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { salesAdvisorSeasonProgramSeasonPostulantSearch } from '@/api/SalesAdvisor/SalesAdvisorSeasonProgramSeasonPostulant';
import { getAllSeasons } from '@/api/Season/Season'
import PostulantPaymentCellComponent from '@/components/Admin-Employee/Postulant-Participant/List/PostulantPaymentCellComponent.vue';

export default {
    name: "PostulantsList",
    components: { PostulantPaymentCellComponent },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        input_to_search: '',
        order_type: 'created',
        //
        seasonOptions: [],
        seasonSelected: 2,
        getSeasonOptionsLoading: false,
    }),

    created() {
        this.searchSalesAdvisorPostulant()
        this.getSeasonsData()
    },

    computed: {
        salesAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-ventas')
        }
    },

    methods: {
        searchSalesAdvisorPostulant (pPage = 1) {
            this.loadingTableData = true
            let IdSalesAdvisor = this.salesAdvisorPermissionData.attributes.typeAdvisor_id   // ok
            let IdSeason = this.seasonSelected

            const SearchForm = new FormData()
            SearchForm.append('input_to_search', this.input_to_search)
            SearchForm.append('order_type', this.order_type)

            salesAdvisorSeasonProgramSeasonPostulantSearch(SearchForm, IdSalesAdvisor, IdSeason, pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        paginationCLiked (e) {
            this.searchSalesAdvisorPostulant(e)
        },

        getSeasonsData() {
            this.getSeasonOptionsLoading = true

            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                })
                .catch(err => {
                    console.log('getAllSeason error', err.response);
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        listOrderBy(pType) {
            this.order_type = pType
            this.searchSalesAdvisorPostulant()
        },

        reloadData () {
            this.searchSalesAdvisorPostulant()
            this.getSeasonsData()
        }
    }
}
</script>

<style scoped>
.alwaysShow {
    /* background-color: rgb(94, 114, 228, .05); */
    z-index: 100;
}
@media (min-width: 576px) {
    .alwaysShow {
        position: sticky;
        background-color: #f7f8fe;
        border-bottom: 2px solid #e9ecef !important;
    }
}
.alwaysShow.details {
    left: 0px;
}
.alwaysShow.name {
    left: 80px;
}
</style>
