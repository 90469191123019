<template>
    <b-row class="mb-4">
        <b-col cols="12" class="mb-4">
            <!-- card -->
            <b-card class="shadow" no-body>
                <!-- card header -->
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                LISTA DE RECURSOS
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>Archivo</th>
                            <th>Recurso</th>
                            <th>descripción</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>

                            <td>
                                <!-- <a :href="row.attributes.file" target="_blank"> -->
                                <b-button variant="outline-primary" size="sm" :href="row.attributes.file" target="_black">
                                    <b-icon icon="file-earmark-text" class="m-0 p-0"></b-icon>
                                </b-button>
                            <!-- </a> -->
                            </td>

                            <td>{{ row.attributes.name}}</td>

                            <td>{{ row.attributes.description}}</td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramResources } from '@/api/ProgramSeason/ProgramSeasonProgramResource';

export default {
    name: "ProgramResources",
    data: () => ({
        tableData: [],
        loadingTableData: false,
    }),
    created() {
        this.getProgramResourcesData()
    },
    methods: {
        // Resource Table Data
        getProgramResourcesData () {
            this.loadingTableData = true

            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            getProgramResources(IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .catch( err => {
                    console.log('getProgramResources Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getProgramResources request end');
                })
        },
        reloadData () {
            this.getProgramResourcesData()
        }
    }
}
</script>