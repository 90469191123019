import axios from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

function storeProgramResource (pFormData) {
    const URI = `${BASE_URL}/api/programResources`
    return axios.post(URI, pFormData)
}

function updateProgramResource (pIdProgramResource, pFormData) {
    const URI = `${BASE_URL}/api/programResources/${pIdProgramResource}`
    return axios.post(URI, pFormData)
}

function destroyResource (pIdProgramResource) {
    const URI = `${BASE_URL}/api/programResources/${pIdProgramResource}`
    return axios.delete(URI)
}

export {
    storeProgramResource,
    updateProgramResource,
    destroyResource
}