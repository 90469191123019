<template>
    <program-resources />
</template>

<script>
import ProgramResources from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramResources.vue'

export default {
    name: "InternshipResources",
    components: { ProgramResources },
}
</script>