<template>
    <contract-create-component :routeNameToContractsList="route_name" :advisor="'SA'">
    </contract-create-component>
</template>

<script>
import ContractCreateComponent from '@/components/Admin-Employee/Postulant/Contracts/ContractCreateComponent.vue'

export default {
  components: { ContractCreateComponent },
    name: 'ContractCreate',
    data: () => ({
        route_name: 'employee-postulants-contracts-SA'
    })
}
</script>